.rectangle-btn {
  margin-right: 10px;
  box-sizing: border-box;
  height: 45px;
  width: 146px;
  border-radius: 51px;
  background-color: #6178ff;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.11px;
  cursor: pointer;
}

.rectangle-btn:hover {
  border: 2px solid #6178FF;
}

.button-text{
  padding-right: 5px;
}

.copy-icon::before{
  font-family: Awesome;
  font-size: 15px;
  font-weight: 400;
  content: '\f0c5';
}

