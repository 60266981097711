body, html {
  height: 100%;
  text-align: start;
  font-family: 'Heebo', sans-serif;
}
.main-container {
  color: #121A4E;
  /* height: 100vh; */
  padding: 0 84px 35px;
  font-family: 'Heebo', sans-serif;
  width: 97%;
  margin: auto;
  -ms-overflow-style: none;
}
.main-container::-webkit-scrollbar {
  display: none;
}


.main-container .headerDiv {
  display: flex;
  padding: 0 0 10px;
  justify-content: space-between;
  position: relative;
  margin-bottom: 33px;

}

.main-container .sticky {
  background-color: #F8F9FF;
  /* height: 75px; */
  display: flex;
  justify-content: space-between;
  position: sticky;
  top:0px;
  left: 0rem;
  right: 13rem;
  z-index: 999;
  padding-top: 35px;
}

.main-container .createHeader {
  font-size: 35px;
  font-weight: bold;
  letter-spacing: -0.28px;
  line-height: 40px;
}
.wrap-logo-img{
  position: relative;
}

.main-container .logo {
  margin-top: -33px; 
}
.main-container .lock{
  position: relative;
  /* right: -15px; */
  top: 3px;
  margin-right: -10px;
}

.description {
  margin-top: 33px;
  width: 100%;
  box-shadow: 0 6px 17px 0 rgba(18, 26, 78, 0.08);
  margin-bottom: 20px;
  padding-right: 20px;
  background-color: #FFFFFF;
}

.description .title {
  color: #121A4E;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 37px;

}

.description .body {
  color: #121A4E;
  font-size: 18px;
  letter-spacing: -0.14px;
  line-height: 27px;
  padding: 11px 0px 26px 22%;
}

.event-icon {
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  margin: 1px;
  margin-left: 10px;
  border: 1px solid #6178FF;
  border-radius: 3px;
  /*background-color: #F8F9FF;*/
  cursor: pointer;
}

.event-icon-disabled {
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  margin: 1px;
  margin-left: 10px;
  border: 1px solid #adb5bd;
  border-radius: 3px;
  /* background-color: #F8F9FF; */
}

.edit-follow {
  color: #121A4E;
  font-family: Heebo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.13px;
  line-height: 24px;
}

#edit-title {
  color: #121A4E;
  font-size: 25px;
  letter-spacing: -0.2px;
  line-height: 37px;
}

.event-icon:hover {
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27)
}

.main-container table {
  width: 100%;
  color: #121A4E;
  font-size: 17px;
  letter-spacing: -0.14px;
  line-height: 25px;
}

.table {
  border: none;
  color: #4D89EA;
  table-layout: fixed;
}

.table tr th {
  border: none;
}

.table td {
  vertical-align: middle;
  text-align: center;
}

.table .choose td {
  box-sizing: border-box;
  /*height: 157px;*/
  border: 1px solid #6178FF;
  border-top: 0.114rem solid #6178FF;
  vertical-align: top;

}
.box-shadow  {
  box-shadow: 0 4px 20px 0 rgba(18,26,78,0.14);
}

.table #td-child {
  text-align: right;
  padding: 0px;
  height: 100%;
  /*border: 1px solid #DADDEE*/
}

#forEditor {
  padding: 0;
  /*border: 1px solid #DADDEE;*/
  /*min-width: -webkit-max-content;*/
  min-block-size: -webkit-max-content;
  /*min-height: 1vw;*/


}
#forEditor img{
  display:block; width:100%; height:auto;
}


#editor-container {
  /*height: 375px;*/
}

.table td .quill .editor-wrapper {
  border: none;
  /*-webkit-box-ordinal-group: 1;*/
  /*-moz-box-ordinal-group: 1;*/
  /*-ms-flex-order: 1;*/
  /*-webkit-order: 1;*/
  /*order: 1;*/
}

.table td .quill .ql-container {
  border: none;
}
.desc .quill .ql-container.ql-snow {
/*border: none;*/
  color: #121A4E;
}

.table td .quill .ql-toolbar {
  border: none;
  border-bottom: 1px solid #ccc;
  text-align: start;
  /*-webkit-box-ordinal-group: 2;*/
  /*-moz-box-ordinal-group: 2;*/
  /*-ms-flex-order: 2;*/
  /*-webkit-order: 2;*/
  /*order: 2;*/
}


.table #td-child .d-inline-flex .right {
  width: 45px;
  margin-left: 1rem;
}

.main-container .userIcon {
  height: 26.6px;
  width: 26.6px;
  background-color: rgba(18, 26, 78, 0.1);
  border-radius: 50%;
  font-size: 10.5px;
  font-weight: bold;
}

.done {
  color: #07DCCD;
  /*background-color: rgba(7, 220, 205, 0.06);*/
}

.inProgress {
  color: #B5B4D2;
}

.progress {
  height: 12px;
  width: 295px;
  border-radius: 6px;
  background-color: #E6E9F6;
  box-shadow: inset 0 0 6px 0 rgba(18, 26, 78, 0.06);
}

.progress .progress-status {
  height: 12px;
  border-radius: 6px;
  background-color: #07DCCD;
}
.main-container [id^='extensions']{
  width: 242px;
  box-sizing: border-box;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.22);
  /*position: relative;*/

}

.main-container-print [id^='extensions']{
  width: 242px;
  box-sizing: border-box;
}


[id^='extensions'] .edit {
  height: 88px;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.22);
}

[id^='extensions'] .show {
  cursor: pointer;
  height: 72px;
}

.title {
  line-height: 25px;
  max-width: 160px;
  font-size: 17px;
  letter-spacing: -0.14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sub-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.13px;
  line-height: 24px;
}
.type {
  color: #B5B4D2;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 21px;
}

[id^='extensions'] .icon {

}

.main-container .TagSelected {
  height: 33px;
  color:#FFFFFF;
  border-radius: 19px;
  background-color: #6178FF;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  margin-left: 9px;
  cursor: pointer;
}
.TagNotSelected {
  height: 33px;
  color: #717695;
  border-radius: 19px;
  background-color: #DADDEE;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  margin-left: 9px;
  cursor: pointer;
}

.main-container .comment {
  color: #121A4E;
  letter-spacing: -0.2px;
  margin-left: 30px;
}

.comment .comment-box {
  width: 100%;

}

.comment .comment-title {
  font-size: 25px;
  font-weight: bold;
}

.comment .comment-box .comment-body {
  opacity: 0.3;
  color: #1A1C68;
  font-size: 17px;
  letter-spacing: -0.14px;
  border-bottom: rgba(18, 26, 78, 0.77) solid 1px;
}

.comment .textarea-container {
  position: relative;
  width: inherit;
  height: 109px;
}

.comment .textarea-container textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #EBEEFB;
  border: none;
  margin-top: 10px;
  font-size: 17px;
  padding-top: 15px;
  padding-right: 15px;
}

.comment .textarea-container div {
  position: absolute;
  bottom: 0;
  left: 0;
}

.comment .textarea-container div > button {
  margin: 0 0 0 10px;
  cursor: pointer;
  box-sizing: border-box;
  height: 36px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
}

.fs-16 {
  font-size: 16px;
}
