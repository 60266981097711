.react-add-to-calendar__dropdown ul {
  list-style: none;
  margin: 0;
}
.react-add-to-calendar__dropdown {
  position: absolute;
  /*top: 30px;*/
  width: 10%;
  padding: 5px 0 5px 8px;
  box-shadow: 1px 3px 6px rgb(0 0 0 / 15%);
  border: 1px solid #a8a8a8;
  background-color: #fff;
  text-align: left;
}

.react-add-to-calendar__button--light {
  background-color: #fff;
}
