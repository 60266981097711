.tr {
  text-align: center;
  /*vertical-align: middle;*/

}
.tr .col {
  vertical-align: middle;
  height: inherit;
}
.active-blue{
  color: #6178FF;
  cursor: pointer;
}
.vertically  {
  margin-top: auto;
  margin-bottom: auto;
}

.num_of_children{
  text-align: center;
  color: #6178FF;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 25px;
  margin-bottom: -3px;
}

.showAll {
  /*height: 24px;*/
  width: 145px;
  color: #6178FF;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: center;
}


