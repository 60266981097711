.users-icon::before {
    font-family: Awesome;
    content: " \f0c0";
    font-weight: 400;
    font-size: 15px;
}

.wrap-overview>.line {
    border-left: 1px solid #D7D7D7;
    padding-left: 9px;
    height: 34px;
    align-self: center;
}

.wrap-overview>.owner-separator {
    border-left: 1px solid #6178ff;
    margin-left: 2px;
    margin-right: 2px;
    height: 24px;
    align-self: center;
}

.overview-text {
    font-size: 15px;
    font-weight: 500;
    line-height: 40px;
    margin-left: 9px;
}

.owner-separator {
    /* margin: 2px; */
}

.shareContainer {
    position: absolute;
    width: 500px;
    background-color: #FFFFFF;
    z-index: 20;
    border-radius: 10px;
    padding: 0 20px;
    margin: 15px 10px 0 0;
    background: #FFFFFF;
    border: 1px solid rgba(190, 199, 229, 0.3);
    box-shadow: 0px 0px 50px rgba(204, 210, 227, 0.45);
}

.sharing-header {
    font-size: 15px;
    font-weight: 700;
    border-bottom: #DBDFF8 solid 1px;
    padding: 20px 0;
    display: flex;
    align-items: center;
}

.wrap-header-icon {
    height: 38px;
    width: 38px;
    background-color: #E7EBFF;
    border-radius: 50%;
    margin: 0 0 0 10px;
}

.header-icon {
    position: relative;
    top: 9px;
    right: 9.5px;
}

.header-icon::before {
    font-family: Awesome;
    content: " \f0c0";
    font-weight: 400;
    font-size: 15px;
    color: #6178FF;
    height: 18px;
    width: 18px;
    display: inline-block;
}

.sub-header {
    margin: 16px 0;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.5;
}

.wrap-input {
    background: #FFFFFF;
    border: 1px solid #6178FF;
    border-radius: 8px;
    height: 40px;
    caret-color: #6178FF;
}

.search-icon {
    vertical-align: middle;
    padding: 12px;
}

.search-icon::before {
    font-family: Awesome;
    content: '\f002';
    font-weight: 900;
    font-size: 16px;
    color: #6178FF;
    vertical-align: middle;
}

.wrap-input input {
    border: none;
    /* margin-right: 41px; */
    line-height: 36px;
}

.shared-user-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 460px;
    overflow-x: hidden;
    padding: 4px 0 0 0;
}

.group-user-wrap {
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.group-user-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.users-header {
    padding: 8px 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
}

.user-row {
    display: flex;
    padding: 5px 0;
    border-bottom: #DBDFF8 solid 1px;
    height: 50px;
    justify-content: space-between;
}

.user-row:hover {
    background-color: #E7EBFF;
    border-radius: 8px;
}

.user-row:last-child {
    border: none;
}

.right-part,
.left-part {
    height: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.right-part {
    justify-content: start;
}

.left-part {
    justify-content: end;
    padding: 0 12px;
}

.user-logo {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #6178FF;
    margin: 0;
    text-align: center;
}

.shared-user-logo {
    width: 40px;
    height: 40px;
    border: #E7EBFF solid 2px;
    border-radius: 50%;
    background-color: #6178FF;
    margin: 0 0 0 -6px;
    text-align: center;
    /* z-index: 30; */
}

.shared-user-logo.owner {
    margin: 0;
}

.remove-user-icon-wrap {
    position: absolute;
    z-index: 33;
}

.remove-user-circle {
    height: 24px;
    width: 24px;
    border: #DADDEE solid 1px;
    border-radius: 50%;
    background-color: #FFFFFF;
    position: relative;
    right: 25px;
    top: -4px;
    cursor: pointer;
}

.prevent-event {
    pointer-events: none;
}

.remove-user-icon {
    position: relative;
    top: -.5px;
}

.remove-user-icon::before {
    font-family: Awesome;
    font-weight: 400;
    font-size: 12px;
    color: #6178FF;
    content: "\58";
}

.user-logo-text {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 20% 0;
    line-height: 22px;
    text-align: center;
    width: 36px;
    height: 36px;
}

.wrap-logo-text-over {
    position: absolute;
    z-index: 29;
}

.logo-text-over {
    position: relative;
    top: 40px;
    background-color: #E7EBFF;
    border-radius: 10px;
    padding: 5px;
}

.into-user-plus {
    width: 25.5px;
    height: 26px;
    border: 0.6375px dashed #6178FF;
    border-radius: 50%;
}

.plus-icon {
    position: relative;
    top: -23.5px;
    right: 6.5px;
}

.plus-icon::before {
    font-family: Awesome-Duotone;
    font-weight: 900;
    font-size: 15px;
    color: #6178FF;
    content: "\2b";
}

.user-name {}

.user-plus {
    border: #E7EBFF solid 3.75px;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    cursor: pointer;
    z-index: 33;
}

.handler-button {
    text-align: left;
    padding: 15px 0;
}

.logo-text-popup > .popover{
    border: none;
    border-radius: 10px;
}

.logo-text-popup .popover-inner {
    border: 1px solid rgba(190, 199, 229, 0.3);
    box-shadow: 0px 0px 50px rgba(204, 210, 227, 0.45);
    border-radius: 10px;
    border: none;
}

.logo-text-popup .popover-body {
    background-color: #FFFFFF;
    color: #121A4E;
    border: none;
    border-radius: 10px;
    line-height: 5px;
}