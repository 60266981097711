


.username-logo {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #07DCCD;
    margin: 0 auto;
    border: solid #282C45 4px ;
}
.username-logo-text {
    color: #121A4E;
    padding: 20%;
}
.username-text{
  font-size: 17px;
}
