.modal-background {
    height: 100%;
    width: 100%;
    background-color: rgba(18,26,78,0.23);
    position: fixed;
    top: 0;
    left: 0;
}
.main-div-delete-task-modal {
    height: 410px;
    width: 511px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 30px 0 rgba(75,99,241,0.1);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 262px;
}
.big-circle {
    border-radius: 50%;
    background-color: rgba(97,120,255,0.1);
    height: 160px;
    width: 160px;
    margin-top: 37px;
    margin-left: auto;
    margin-right: auto;
}
.small-circle {
    height: 96px;	
    width: 96px;	
    background-color: #FFFFFF;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    position: relative;
}
.delete-img {
    position: absolute;
    top: 25px;
    right: 26px;
}
.delete-modal-text {
    color: #201E75;
    font-family: 'Heebo', sans-serif;
    font-size: 25px;
    letter-spacing: -0.2px;
    margin-left: auto;
    margin-right: auto;
}
.delete-button-wrap{
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
}
.delete-task-btn {
    height: 45px;
    width: 142px;
    background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    border: unset;
    margin-left: auto;
    margin-right: auto;
    margin-top: 22px;
    margin-bottom: 8px;
    color: #FFFFFF;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;	
    font-weight: bold;	
    letter-spacing: -0.13px;
}
.cancel-btn {
    border: unset;
    background: unset;
    color: #6178FF;
    font-size: 14px;
    letter-spacing: -0.11px;
    font-family: 'Heebo', sans-serif;
}