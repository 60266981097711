.dropdown .dropdown-btn{
  background-color: inherit;
  border: none;
}

.dropdown-item{
  height: 60px;
  /*width: 196px;*/
  /*transform: scaleX(-1);*/
  /*background-color: #F8F9FF;*/
}


