.div-more-ifo {
  /* height: 100vh; */
  width: 18%;
  background-color: #f2f4fc;
  padding-top: 58px;
  padding-left: 29px;
  padding-right: 30px;
  position: relative;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
}
.more-info-header {
  color: #1a1d68;
  font-family: 'Heebo', sans-serif;
  font-size: 30px;
  letter-spacing: -0.24px;
}
.line-ander-header {
  margin-top: 8px;
  box-sizing: border-box;
  height: 2px;
  border: 1px solid rgba(18, 26, 78, 0.07);
}
.line-ander-Leading-mission {
  box-sizing: border-box;
  height: 2px;
  margin-top: 26.25px;
  border: 1px solid rgba(18, 26, 78, 0.07);
  box-shadow: 0 1px 0 0 rgba(224, 228, 246, 0.27);
}
.div-status-a {
  justify-content: space-between;
}
.status-text {
  color: #121a4e;
  font-family: 'Heebo', sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-top: 24px;
}
.status-num {
  color: #1a1d68;
  font-family: Heebo;
  font-size: 25px;
  letter-spacing: -0.2px;
  margin-top: 16px;
}
.more-info-progress {
  height: 12px;
  margin-top: 2px;
  border-radius: 6px;
  background-color: #e6e9f6;
  box-shadow: inset 0 0 6px 0 rgba(18, 26, 78, 0.06);
}
.more-info-progress-colourful {
  height: 12px;
  margin-top: 2px;
  border-radius: 6px;
  background-color: #07dccd;
  box-shadow: inset 0 0 6px 0 rgba(18, 26, 78, 0.06);
}
.Leading-mission-text {
  color: #121a4e;
  font-family: Heebo;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-top: 25px;
}
.select-leads-btn {
  /* background: url('../../../ui/images/arrow_down.svg'); */
  background-repeat: no-repeat;
  background-position: left;
  margin-top: 17px;
  box-sizing: border-box;
  background-color: #f2f4fc;
  border: unset;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 100%;
  color: #151b5a;
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  letter-spacing: -0.13px;
  -webkit-appearance: none;
  padding-bottom: 10px;
}
.select-leads-btn-intenal {
  display: flex;
  justify-content: space-between;
}
.select-leads-btn-logo {
  margin-bottom: 4px;
}
.select-leads-btn-text {
  margin-bottom: 11px;
}
.forum-header {
  display: flex;
  margin-top: 24.25px;
}
.forum-header-text {
  color: #121a4e;
  font-family: 'Heebo', sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-right: 9px;
}
.div-chat-box {
  padding: 11px 14px 10px 15px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  /* זמני */
  margin-top: 10px;
  /* זמני */
}
.chat-text-me {
  color: rgba(18, 26, 78, 0.52);
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-box-footer {
  /* color: #C6C4DF;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    letter-spacing: -0.11px; */
  justify-content: space-between;
  display: flex;
  margin-top: 11px;
}
.chat-name-me {
  font-family: Heebo;
  color: #151c5b;
  margin-right: 4px;
}
.chat-write-message {
  border: unset;
  min-height: 109px;
  width: 100%;
  border-radius: 3px;
  background-color: #ebeefb;
  /* opacity: 0.6;	 */
  color: #281f84;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
  padding-top: 15px;
  padding-right: 17px;
}
.under-write-message {
  margin-top: 9px;
  /* Tuvia */
  /* display: flex; */
  justify-content: space-between;
}
.write-by {
  color: #c6c4df;
  font-family: Heebo;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.forum-send-message {
  border: unset;
  box-sizing: border-box;
  height: 36px;
  width: 74px;
  border: 1px solid #daddee;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
  color: #a5a6c1;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  margin-right: 6px;
}
.attach-files-btn {
  border: unset;
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  border: 1px solid #daddee;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
  color: #a5a6c1;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
}
.div-ander-ander-write-message-write-by {
  margin-top: 7px;
}
.list-of-leads {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 20px 34px 0 rgba(21, 36, 120, 0.21);
  position: absolute;
  width: 297px;
  top: 298px;
  left: 29px;
  padding: 5px;
}
.forum-chat {
  margin-top: 6px;
  margin-bottom: 25px;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  max-height: 200px;
  min-height: 100px;
}
.no-message-text {
  opacity: 0.3;
  color: #281f84;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
  margin-top: 21px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(18, 26, 78, 0.4);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #121a4e;
}

@media (max-width: 500px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .div-more-ifo {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    width: 25%;
  }
  .more-info-header {
    display: none;
  }
  .line-ander-Leading-mission {
    margin-top: 0px;
  }
  .headerDiv {
    display: block;
  }
  .createHeader {
    font-size: 10px;
    font-weight: 700;
  }
  .forum-send-message {
    width: 52px;
  }
  .CreateTaskPage {
    width: 60%;
  }
  .forum-chat {
    min-height: 65px;
  }
}
