.upload-btn-wrapper {
  position: relative;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.upload-btn{
  height: 45px;
  width: 146px;
  border-radius: 51px;
  background-color: #6178ff;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.11px;
  /* cursor: pointer; */
}

.upload-btn:hover {
  border: 2px solid #6178ff;
}

