.div-all-find {
    position: relative;
    z-index: 999999;
}
.all-find {
    position: absolute;
    height: 325px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 34px 0 rgba(21,36,120,0.21);
    padding: 10px;
  border-top: 2px solid #6178FF;
}
