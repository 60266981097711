.mission-array-history-header {
    margin-top: 24.75px;
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.1px;
}
.mission-array-history {
    margin-top: 15px;
    display: flex;
}
.mission-array-history-logo {
    margin-bottom: auto;
}
.mission-array-history-text {
	/*color: #B5B4D2;*/
	font-family: 'Heebo', sans-serif;
	font-size: 14px;
  letter-spacing: -0.11px;
  margin-right: 10px;
}
.mission-array-history-updates {
	color: #B5B4D2;
	font-family: 'Heebo', sans-serif;
	font-size: 14px;
  letter-spacing: -0.11px;
}
.mission-array-update-history-logo {
    margin-left: 10px;
    margin-bottom: auto;
}
