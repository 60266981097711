.div-all-cards-vertical {
  flex-wrap: wrap;
  /* margin-top: 32px; */
  height: calc(100vh - 110px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.wrap-path {
  flex: 0 0 100%;
  margin: 46px 0 24px;
  display: flex;
  justify-content: space-between;
}

.path-right-part {}

.path-left-part {
  margin-left: 25px;
}

.bagin-path {
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  cursor: pointer;
}

.continu-path {
  font-weight: 400;
  cursor: pointer;
}

.add-task-icon::before {
  font-family: Awesome-Duotone;
  font-weight: 900;
  color: #FFFFFF;
  content: "\2b";
}

.add-window {
  position: absolute;
  height: 200px;
  width: 200px;
  border: solid #987789 1px;
}

.add-task,
.folder-actions-popover {
  background-color: #FFFFFF;
  border: 1px solid rgba(190, 199, 229, 0.3);
  box-shadow: 0px 0px 50px rgba(204, 210, 227, 0.45);
  border-radius: 10px;
}

.add-task>.popover-inner>.popover-body,
.folder-actions-popover>.popover-inner>.popover-body {
  background-color: #FFFFFF;
  color: #121A4E;
  width: 162px;
  border: none;
  border-radius: 10px;
}

.add-item {
  height: 37px;
  border-radius: 8px;
  line-height: 37px;
  text-align: start;
  padding: 0 16px;
  cursor: pointer;
}

.add-item:hover {
  background-color: #E7EBFF;
}

.add-item-taxt {}

.add-item-icon {}

.add-item-icon::before {
  width: 14px;
  height: 14px;
  font-family: Awesome;
  font-weight: 400;
  font-size: 14px;
  padding: 0 0 0 10px;
  color: #6178FF;
}

.add-folder-icon::before {
  content: '\e18c';
}

.task-icon::before {
  content: '\f319';
}

.pen-icon::before{
  content: '\e212';
}

.delete-icon::before{
  content: '\f1f8';
}

.metarim{
  font-weight: 400;
  color: #6178FF;
}
