.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
   max-width: 40%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0,0.2);
  border-radius: 0px;
  box-shadow: rgb(18, 26, 78, 0.2) 0px 4px 20px 0px;
}

.popover-body{
  color: white;
  background-color: #808799;
  font-family: Heebo;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #808799;
}
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #808799;
}

 /* for editor popover*/
.bs-popover-top .arrow::after,
.po-white .bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: white;
}
.bs-popover-bottom .arrow::after,
.po-white .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: white;
}

.po-white .popover-body{
  background-color: white;
}

.popover-body .quill .ql-container.ql-snow {
  border: none;
}

.popover-body .ql-editor {
  direction: rtl;
  text-align: right;
  font-size: 14px !important;
  font-family: 'Heebo', sans-serif;
  color: #121A4E;
  box-sizing: border-box;
  line-height: 1.32;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 5px 5px;
  tab-size: 4;
  -moz-tab-size: 4;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.divider {
  height:20px;
  width:1px;
  background-color:white;
  margin-left:5px}
