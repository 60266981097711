.registration_page{
  width: 100%;
  overflow: auto;
}
.create_form{
  padding: 30px;
  background-color: white;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.PhoneInputInput {
  direction: rtl;
}
#cu_phone .div-create-user {
    height: 332px;
    width: 1524px;
    border-radius: 3px;
    background-color: #FFFFFF;
    padding: 30px;
}
#group{

}
.create-user-header-a {
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.13px;
    margin-bottom: 19px;
}
.create-user-header-b {
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.13px;
    margin-top: 31px;
}
.sort-input-width {
    padding-left: 53px;
}
.creat-user-state {
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.12px;
    margin-top: 104px;
    margin-bottom: 26px;
}
.create-user-btn {
    margin-top: 26px;
    display: flex;
}
.create-user-btn-cancel {
    margin-top: 12px;
    margin-right: 20px;
    color: #6178FF;
}
.main-div-creat-user {
    background-color: #F8F9FF;
    padding-right: 30px;
}
