.search-field{
  border: unset;
  height: 60px;
  width: 85%;
  color: #121A4E;
  font-family: 'Heebo', sans-serif;
  font-size: 25px;
  letter-spacing: -0.2px;
  /*border-bottom: 2px solid rgba(18, 26, 78, 0.16);*/
  padding-right: 20px;
  background-color: #E7EBFF;
}
.search-field .search-input {
    border: unset;
    /*height: 55px;*/
    /*width: 100%;*/
    /*color: #121A4E;*/
    /*font-family: 'Heebo', sans-serif;*/
    /*font-size: 25px;*/
    /*letter-spacing: -0.2px;*/
    /*border-bottom: 2px solid rgba(18, 26, 78, 0.16);*/
    padding-right: 20px;
    background-color: #E7EBFF;

}
.search-field .search-input-border-bottom {
    box-sizing: border-box;
    height: 1px;
    width: 1533px;
    border: 2px solid #121A4E;
    opacity: 0.16;
}
.search-field .div-search-input-logo {
  /*background-color: #E7EBFF;*/
    /* border-bottom: 2px solid rgba(18, 26, 78, 0.16); */
    width: 25px;
    padding-left: 3px;
    line-height: 60px;
}
