
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
    font-size: 13px !important;
}

.ql-size-small,
.ql-size span[data-value="small"]::before {
    font-size: 14px !important;
}

.ql-size-medium,
.ql-size span[data-value="medium"]::before {
    font-size: 18px !important;
}

.ql-size-large,
.ql-size span[data-value="large"]::before {
    font-size: 20px !important;
}
.ql-editor {
  direction: rtl;
  text-align: right;
  font-size: 19px !important;
  font-family: 'Heebo', sans-serif;
  color: #121A4E;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: 0px;
  margin-right: 0.3em;
  text-align: right;
}

.quill-always-hidden-toolbar{
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
.editMode .ql-container.ql-snow {
  border: none;
  background-color: inherit;
}
.ql-container.ql-snow {
  background-color: white;
}

.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
}
.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
   padding:0 2px 0 6px;
  /*padding-bottom: 8px;*/
}

.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
  z-index: 999;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 8px;
}
