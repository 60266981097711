.yesod-modal-layer{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    background-color: rgba(255,255,255,0.8)
}
.yesod-modal{
    background-color: #FFFFFF;
    height: 564px;	
    width: 950px;
    box-shadow: 0 20px 30px 0 rgba(75,99,241,0.1);
    border-radius: 8px;
    float: none;
    margin-top: 106px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
}
.modal-logoUp {
    padding: 5px;
}
.modal-logo {
    height: 80px;	
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    margin-top: -80px;
}
.modal-to-system-text {
	color: #121A4E;
	font-family: 'Heebo', sans-serif;
	font-size: 25px;
	font-weight: bold;
    line-height: 37px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 25px;
}
.gutter-box {
    margin-right: 10px;
    margin-left: 10px;
}
.modal-details-header {
	color: #121A4E;
	font-family: 'Heebo', sans-serif;
	font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
}
.modal-btn {
    height: 45px;
    width: 422px;
    background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    border: unset;
    color: #FFFFFF;
    font-family: 'Heebo', sans-serif;	
    font-size: 16px;	
    font-weight: bold;
    letter-spacing: 1.19px;
}
.modal-checkbox {
    width: 24px;
    height: 24px;
}
.custom-control {
    padding-right: 2rem;
    margin-top: 10px;
    color: #121A4E;	
    font-family: 'Heebo', sans-serif;	
    font-size: 16px;
    letter-spacing: -0.13px;
}

.custom-control-label::before {
    background-color: #DEE5FE;
    border: 1px solid #BEC7E5;
    border-radius: 0px;
}
.custom-control-label::before, .custom-control-label::after {
    top: 0;
    border: 1px solid #BEC7E5;
    right: -2rem;
    width: 24px;
    height: 24px;
    border-radius: 0px;
}
