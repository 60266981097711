.yesod_logo_sidebar {
  margin-left: auto;
  /*padding-top: 10px;*/
  display: block;
  margin-bottom: 20px;
}

.yesod_logo_sidebar_close {
  margin: 10px auto 20px auto;
  padding-top: 10px;
  display: block;
}

#header {
  /*position: absolute;*/
  /*width: 260px;*/
  max-width: 14vw
}

#header .pro-sidebar {
  height: 100vh;
}

#header .header-top {
  background-color: #323654;
  height: 104px;
}

#header .bottom-header {
  margin-top: -20px;
  height: 100px;
  text-align: center;
  color: white;
  font-family: 'Heebo', sans-serif;
  font-weight: bold;
}

#header .closemenu {
  left: 18px;
  color: white;
  position: absolute;
  /*right: 0;*/
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 17px;
  cursor: pointer;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: #282C45;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
  
}

#header .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  /* padding: 0 5px; */
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  font-size: 17px;
  color: white;
  margin: 10px 0px;
  font-weight: bold;
  height: 61px;
}

#header .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  /*text-align: right;*/
  /*flex-grow: 1;*/
  /*flex-shrink: 1;*/
  padding-right: 12px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #282C45;
  color: white;
  border-radius: 3px;
  height: 38px;
  width: 38px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  /*color: #000;*/
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  position: relative;
  background-color: #20243A !important;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  display: none;
}


#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #282C45;
  color: white;
  border-radius: 3px;

}
#header .pro-sidebar-inner .pro-sidebar-layout ul .circleIcon .pro-inner-item .pro-icon-wrapper {
  background-color: white;
  color: white;
  border-radius: 20px;
  height: 38px;
  width: 38px;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
  border: solid #4860E8 1px;
  border-radius: 20px;
  background-color: white !important;
  display: inline-block !important;
}
#header .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  background-color: #282C45;
  padding-right: 20px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  /*background-image: linear-gradient(50deg, #eff1fc 0%, #bab6b4 100%);*/
  background: #6178FF;
  /*border-radius: 0px 25px 25px 0px;*/
  margin-left: -10px;
  margin-right: -30px;
  padding-right: 30px;
}

#header .logo {
  padding: 20px;
}

#addNewFolder {
  /*Font: Heebo Bold;*/
  Size: 16px;
  Line_height: 24px;
  Letter: -0.13px;
  Align: right;
  color: #6178FF

}

#header .pro-sidebar-inner .pro-sidebar-layout ul .active .pro-icon-wrapper {
background-color: inherit;
}

.pro-menu-item.pro-sub-menu.open > .react-slidedown.pro-inner-list-item{
  max-height: calc(100vh - 355px) ;
  /* max-height: calc(100vh - 555px) ; */
  overflow-y: overlay;
  overflow-x: hidden;
}

.pro-menu-item.pro-sub-menu.open > .react-slidedown.pro-inner-list-item::-webkit-scrollbar-thumb{
  border-radius: 15px;
  background-color: #60637A;
}
.pro-menu-item.pro-sub-menu.open > .react-slidedown.pro-inner-list-item::-webkit-scrollbar{
  width: 5px;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.item-menu:active {
  background-color: #2b542c;
}

.item-menu:hover {
  background-color: #1b8dbb;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
