.copy-task-btn {
    border: unset;
    box-sizing: border-box;
    height: 36px;
    width: 126px;
    border: 1px solid #DADDEE;
    border-radius: 3px;
    background-color: #FFFFFF;
    color: #A4A6C1;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.11px;
}
.copy-task-btn:hover {
    color:#6178ff;
    border: 1px solid #6178ff;
}
.copy-task-btn-is-not-isStaff {
    border: unset;
    box-sizing: border-box;
    height: 36px;
    width: 126px;
    border: 1px solid #DADDEE;
    border-radius: 3px;
    background-color: #FFFFFF;
    color: #A4A6C1;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.11px;
}