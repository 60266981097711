.my-home-page-logo {
    background-color: #FFFFFF;
    height: 51px;
    width: 52px;
    border-radius: 50%;
    padding-top: 7px;
    padding-right: 8px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 7px;
}