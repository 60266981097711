.class-text-main{
    position: relative
}
.login-text-input{
    width: 100%;
    height: 35px;
    border: unset;
    border-bottom: 1px solid rgba(18,26,78,0.6);
    color: #121A4E;
    /*padding: 10px 0;*/
    font-family: 'Heebo', sans-serif;
    margin: 28px 0;
    font-size: 16px;
    /* font-weight: 600; */
}
.class-text-label-login-sm {
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    top: 7px;
    font-family: 'Heebo', sans-serif;
}
