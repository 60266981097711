.background-login {
  width: 100%;
  height: 100vh;
  background: #EEF2FE url('../../../ui//images/loginBackground.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  display: flex;
  flex-direction: column;
}

.login-details {
  height: 472px;
  width: 486px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 20px 30px 0 rgba(75, 99, 241, 0.1);
  margin-top: -20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.login-to-system-text {
  color: #121A4E;
  font-family: 'Heebo', sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  margin-top: -44px;
  margin-bottom: 32px;
}

.class-logo-login-area {
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -78px;
  margin-bottom: 90px;
}

.login-btn {
  height: 45px;
  width: 100%;
  background-color: #6178FF;
  border: unset;
  box-shadow: 0 5px 8px 0 rgba(97, 120, 255, 0.27);
  margin-top: auto;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Heebo', sans-serif;
  cursor: pointer;
}

.class-first-time-login {
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
  color: #121A4E;
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  letter-spacing: -0.13px;
}

.class-login-logoUp {
  padding: 5px;
}

.yesod-login-a {
  font-weight: bold;
  color: #6178FF;
  text-decoration: underline;
}
