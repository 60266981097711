.div-line-find {
    height: 61px;
    width: 1513px;
    display: flex;
    padding: 10px;
    border: unset;
    position: relative;
}
.text-find-header {
    color: #121A4E;
    font-family: Heebo;
    font-size: 15px;
    letter-spacing: -0.12px;
    margin-top: 10px;
    margin-right: 7px;
}
.yesod-find-logo {
    position: absolute;
    top: 35px;
    right: 5px;
}
