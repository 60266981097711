.folder-area,
.tasks-area {
  margin: 20px 0;
}

.tasks-area {
  display: flex;
  flex-wrap: wrap;
}

.folders-header {
  flex: 0 0 100%;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
}

.folder-builder-wraper {
  display: flex;
  flex-wrap: wrap;
}

.folder-card {
  width: 260px;
  height: 82px;
  /* color: #FFFFFF; */
  color: #121A4E;
  /* background: linear-gradient(123.73deg, #6178FF -20.43%, #4860E8 93.12%); */
  background: #CBD2FA;
  border-radius: 10px;
  margin: 0 5px 24px 20px;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  cursor: pointer;
}

.folder-card:hover {
  box-shadow: 0 10px 20px 0 rgba(21, 36, 120, 0.13);
}

.folder-right-part {
  display: flex;
  align-items: center;
}

.folder-left-part {}

.folder-drag-over {
  width: 274px;
  height: 96px;
  background-color: #E7EBFF;
  border: 1px solid #6178FF;
  border-radius: 14px;
  margin: 0 0 10px 11px;
}

.folder-no-over {
  background-color: none;
  border: none;

}

.is-dragging {
  opacity: 50%;
}

.folder-drag-over>.folder-card,
.folder-no-over>.folder-card {
  position: relative;
  top: 6px;
  right: 5px;

}

.folder-icon-wraper {
  background-color: #6178FF;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  margin-left: 13px;
}

.folder-icon {
  position: relative;
  top: 7px;
  right: 11.5px;
}

.folder-icon::before {
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 900;
  font-family: Awesome;
  content: "\f07b";
}

.action-icon-wraper {
  width: 8px;
  text-align: center;
}

.action-icon {
  top: 16px;
}

.action-icon::before {
  color: #4d89ea;
  font-size: 15px;
  font-weight: 900;
  font-family: Awesome;
  content: "\f142";
}

.folder-name {
  max-width: 165px;
  max-height: 70px;
  overflow: hidden;
}

.folder-name-input {
  background: #FFFFFF;
  /* border: 1px solid #6178FF; */
  border: none;
  border-radius: 8px;
  height: 30px;
  /* caret-color: #6178FF; */
  caret-color: #CBD2FA;
  padding: 0 10px;
  color: #121A4E;
  width: 150px;
}

.display-none {
  display: none;
}

.between-cards {
  position: relative;
}

.wrap-line-between {
  position: absolute;
  width: 50px;
  z-index: 20;
}

.wrap-line-between.folder {
  left: -22px;
  top: 6px;
  height: 82px;
}

.wrap-line-between.folder.first {
  left: -26px;
}

.wrap-line-between.task {
  left: -25px;
  top: 12px;
  height: 177px;
}

.wrap-line-between.task.first {
  left: -26px;
}

.line-between-tasks {
  border: #6178FF 1px solid;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
}

.line-between-tasks.folder {
  margin-top: 6px;
  height: 67px;
}

.line-between-tasks.task {
  margin-top: 5px;
  height: 164px;
}