input,
button,
textarea,
select {
    outline: unset;
}

button {
    border: unset;
    background: unset;
    cursor: pointer;
}

iframe {
    display: none;
}

@font-face {
    font-family: 'Awesome-Brands';
    src: url('./ui/fonts/Awesome/Brands-Regular-400.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome';
    src: url('./ui/fonts/Awesome/Pro-Thin-100.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome';
    src: url('./ui/fonts/Awesome/Pro-Light-300.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome';
    src: url('./ui/fonts/Awesome/Pro-Regular-400.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome';
    src: url('./ui/fonts/Awesome/Pro-Solid-900.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome-Duotone';
    src: url('./ui/fonts/Awesome/Duotone-Solid-900.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}