.my-tag {
    margin-top: 23px;
    margin-right: 13px;
    border-radius: 30px;
    padding: 0px 9px 0px 9px;
    border: 1px solid rgba(18,26,78,0.1);
    line-height: 1.9;
    background: #FFFFFF;
}
.my-tag-to-all {
    margin-top: 23px;
    margin-right: 13px;
    border-radius: 30px;
    padding: 0px 9px 0px 9px;
    border: 1px solid rgba(18,26,78,0.1);
    line-height: 1.9;
    background: #6178FF;
    color: #FFFFFF;
}

.TagSelected {
  height: 33px;
  color:#FFFFFF;
  border-radius: 19px;
  background-color: #6178FF;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  margin-left: 9px;
  cursor: pointer;
}

.TagNotSelected {
  height: 33px;
  color: #717695;
  border-radius: 19px;
  background-color: #DADDEE;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  margin-left: 9px;
  cursor: pointer;
}
