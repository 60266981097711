.hiddenPage{
     /* display: none; */
    /* border: 1px solid black; */
    margin: 0px;
    direction: rtl;
    width: 100%;
    padding: 10px;
     /*position: fixed;*/
    background-color: white;
}

table{
    table-layout: fixed;
}
.config-page{
    width: 100%;
}

.img-config{
    position: relative;
    height: 120px;
    margin-top:20px;
    margin-right: 31px;
    z-index: 2;
}

.content-config{
    padding: 60px;
    margin-top: 20px;
    color: #121A4E;
    font-size: 25px;
    padding-top: 10px;
}

h3.config{
    margin-top: 50px;
}

.descripation-title{
    font-weight: bold;
    font-weight:700;
    letter-spacing: -0.2px;
    line-height: 37px;
}

.circlel{
    position: relative;
    right: -10px;
    /* top: 15px; */
    /* height: 10px; */
    display: inline-block;
    /* height: 0px; */
    line-height: 20px;
    font-size: 70px;
    color: #dee2e6;
    width: 15px;
}

.circlel-child{
    font-size: 50px;
    right: 40px;
}

.process-content{
    font-size: 22px;
}

.td-border-top{

    border-top: 2px solid #dee2e6;
}

div tbody tr.tr-border-top{
    border-top: 2px solid #dee2e6;
}
div > table > tbody > tr.tr-border-top:first-child{
    border-top: none;
}
div > table > tbody > tr.tr-border-top:last-child{
    border-bottom:2px solid #dee2e6;
}

.tr-margin{
    padding-top: 35px;
    padding-bottom: 5px;
    padding-left: 36px;

}
.tr-margin:first-child{
    /* margin-top: 0; */
}

.tr-child-margin{
    margin-right: 30px;
    margin-left: 30px;
    padding-left: 0px;

}

.date-text{
    color: #6C7582;
}

.mein-header, .mein-header-space{
    height: 75px;
    width: 100%;
    margin-left: 0px;
    margin-right: -10px;
}

.mein-header-space{
    height: 150px;
}

.mein-footer, .mein-footer-space {
  height:110px;
  width: 100%;
  margin-left: 0px;
  margin-right: -10px;
}

.mein-header{
  position:fixed;
  top: 0;
  background-color: #6178ff;
}
.mein-header img{

}

.mein-footer{
  position: fixed;
  bottom: 0;
  background-color: #E4E4E4;
  display: table;
}

#content-footer{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.mein-title {
  font-size: 35px;
  font-weight: bold;
  letter-spacing: -0.28px;
  line-height: 70px;
}

.berake-inside{
  flex: none;
  break-inside: auto !important;
} 
    
.tbody-breaks{
  page-break-inside:auto;
} 

.tr-breaks{
  page-break-inside: avoid;
} 

body{
  height: auto!important;
  float: none!important;
}
    
@media print{
    .hiddenPage{
        display: block;
    } 
    
    .thead-breaks{
        page-break-before: avoid;
    }
    
    table thead tbody tr td {
        display: inline-block;
    }
        
}