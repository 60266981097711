
.option {

}
.option-title {
  height: 27px;
  color: #121A4E;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 22px;
}
.option-description {
  height: 18px;
  color: #727797;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
}

.dropdown-toggle {
  min-width: 100%;
  color: #07DCCD;
  /*[min-width: moz-available];*/
  /*[min-width: webkit-fill-available];*/
  min-width: -moz-max-content;

}
.btn{
  color: #4D89EA;
}
.PhoneInput {
  direction: ltr;
}
.PhoneInputInput{
  border: none;
}
