.callapse{
  font-family: 'Heebo', sans-serif;
  margin-bottom: 20px;
  padding-right: 30px;
  padding-left: 30px;
}

.callapse-close{
  height: 78px;
  cursor: pointer;
  letter-spacing: -0.2px;
  color: #121A4E;
  font-size: 25px;
  font-weight: bold;
  position: relative;
}

.center-v{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
