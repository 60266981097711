.class-copy-task-main {
    height: 100%;
    width: 100%;
    background-color: rgba(18,26,78,0.23);
    position: fixed;
    top: 0;
    left: 0;
}
.copy-task-modal {
    height: 410px;
    width: 511px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 30px 0 rgba(75,99,241,0.1);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 262px;
    padding: 46px 46px 30px 46px;
    position: relative;
}
.copy-task-modal-header1 {
    color: #272084;
    font-family: 'Heebo', sans-serif;
    font-size: 25px;	
    font-weight: 800;	
    letter-spacing: -0.2px;	
    line-height: 37px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.copy-task-modal-header2 {
    color: #1F1E75;
    font-family: 'Heebo', sans-serif;	
    font-size: 25px;	
    letter-spacing: -0.2px;	
    line-height: 37px;
    margin-left: auto;
    margin-right: auto;
}
.cancel-btn {
    background: unset;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    color: #6178FF;
    border: unset;
}
.copy-task-select {
    border-bottom: 2px solid rgba(18,26,78,0.6);
    box-sizing: border-box;
    padding-bottom: 14px;
    display: flex;
    position: relative;
    height: 42px;
}
.copy-task-select-arrow-icon {
    margin-right: auto;
    margin-left: 7px;
}
.copy-task-modal-div-option {
	box-sizing: border-box;
	border: 1px solid #D5D9F0;
	background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
    padding: 16px;
}

.select-label-to-copy-modal {
    color: #151C5A;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    letter-spacing: -0.1px;
    margin-top: 37px;
}
.copy-array-btn {
    height: 45px;
    width: 142px;
    background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    font-family: 'Heebo', sans-serif;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.13px;
    margin-right: 20px;
}
.copy-array-btn-text {
    margin-right: 8px;
}
.div-copy-task-buttons {
    margin-right: auto;
    margin-top: auto;
}
.copy-task-modal-category-option-img {
    height: 24px;
    width: 24px;
    margin-left: 10px;
}

.copy-task-modal-category-option-text {
	color: #181E68;
	font-family: 'Heebo', sans-serif;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: -0.13px;
}
