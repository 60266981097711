.div-task-card {
    width: 364px;
    max-height: 176px;
    border-radius: 3px;
    background-color: #FFFFFF;
    margin: 13px 9px 13px 9px;
    padding: 20px;
    position: relative;
}

.div-task-card>span.wrap-img {
    position: absolute;
    left: 14px;
    top: -15px;
}

.div-task-card>span>img.categoryLogo {
    position: relative;
    /* left: 14px;
    top: -15px; */
}

.div-task-card>span>img.lock {
    position: relative;
    /* margin-right: -10px; */
    margin-top: 15px;
    width: 10px;
    height: 10px;
}

.my-card-header {
    height: 44px;
    width: 299px;
    display: flex;
}

.div-my-card-header-logo {
    margin-top: 1.5px;
    margin-bottom: 1.5px;
}

.div-my-card-header-text {
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.12px;
    /*line-height: 22px;*/
    margin-right: 6px;
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.8em;
}

.my-card-line {
    height: 1px;
    width: 325px;
    border: 1px solid rgba(18, 26, 78, 0.07);
    margin-top: 15px;
}

.my-essence-mission {
    height: 36px;
    color: #727797;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 18px;
    margin-top: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.my-card-footer {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    margin-bottom: 8px;
}

.my-footer-text {
    color: #727797;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.1;
    margin-right: 6px;
    margin-top: 5px;
}

.draft {
    color: #727797;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-top: 6px;
}

.class-draft-header-text {
    height: 18.9%;
    width: 19.8%;
    border-radius: 3px;
    background-color: #F0F0F0;
    color: #8F8F8F;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 18px;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 5px;
}

.card-logo {
    height: 20px;
    width: 20px;
}