.class-password-main {
    display: flex;
    position: relative;
}
.login-password-input{
    width: 100%;
    height: 35px;
    border: unset;
    border-bottom: 1px solid rgba(18,26,78,0.6);
    color: #121A4E;
    padding: 10px 0;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    background-repeat: no-repeat;
    background-position: left;
    margin-top: 28px;
    /* font-weight: 600; */
}
.class-password-label-login-sm {
    position: absolute;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: 600;
    top: 8px;
}
.class-password-incorrect {
    margin-top: 3px;
    color: #FF8B90;	
    font-family: 'Heebo', sans-serif;
    font-size: 12px;	
    letter-spacing: -0.1px;	
}
.class-input-images {
    border-bottom: 1px solid rgba(18,26,78,0.6);
    padding-top: 30px;
}
/* .class-check-logo {
    border-bottom: 2px solid #07DCCD;
} */
