.div-chat-box-user {
  padding: 11px 14px 10px 15px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.chat-text-user {
  color: rgba(18, 26, 78, 0.52);
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-box-footer-user {
  justify-content: space-between;
  display: flex;
  margin-top: 11px;
}
.write-by-user {
  color: #c6c4df;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-name-user {
  font-family: 'Heebo', sans-serif;
  color: #151c5b;
  margin-right: 4px;
}

@media (max-width: 500px) {
  .write-by-user,
  .chat-text-user,
  .chat-name-user {
    font-size: 12px;
  }
  .div-chat-box-user {
    padding: 0px;
  }
}
