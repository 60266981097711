.CreateTaskPage {
  background-color: #f8f9ff;
  padding: 2%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
}

.tox-tinymce {
  width: 900px;
}

.childRow {
  margin-right: 46px;
  width: 95%;
}

.whiteContainer > .childRow {
  margin-right: 55px;
  margin-top: 2px;
  width: 86%;
}

.childRow .tox-tinymce {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px black solid !important;
  margin-bottom: 7px;
  width: 74%;
}

.showProcess {
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: right;
}

.ShowTask {
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  word-wrap: break-word;
}

.ShowTaskChild {
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  word-wrap: break-word;
}

.processLine .tox {
  text-align: right !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px black solid !important;
  margin-bottom: 7px
}

.createHeader {
  height: 44px;
  font-size: 30px;
  min-width: 96px;
  letter-spacing: -0.24px;
}

.tagName {
  font-size: 12px;
  font-weight: bold;
  margin-top: 2%
}

.tagsHeaderForShow {
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
  margin-bottom: 5px;
}

.tagsHeader {
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
}

.NewBolder {
  background-color: #536AEE
}

#maintaskEssence {
  width: 70%;
  display: inline-block
}

.essence {
  font-size: 16px;
  word-wrap: break-word;
  color: #141C5B;
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: right
}

.taskEssence {
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
  margin-bottom: 1%;
  margin-top: 4%;
}

.myLine {
  position: relative;
  top: 60px;
  right: 54px;
  height: 1px;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 500px;
}

.tagsiHeader {
  height: 18px;
  margin-top: 14px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
}

.tagsHeaderCategory {
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: -0.1px;
}

.workProcess {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
  margin-top: 24px;
}

.draftSave {
  font-size: 14px;
  margin: auto 7px;
}

.header {
  height: 18px;
  width: 82px;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
}

#BoldFakeIcon {
  font-size: 26px;
  font-weight: bolder;
  position: relative;
  right: 10px;
}

#essenceHandleChange {
  min-width: 60%;
  min-height: 100px;
  border: black 1px solid;
}

.headerDiv {
  display: flex;
  padding: 0 0 10px;
  justify-content: space-between;
  position: relative;
}

.dubleHeader {
  display: flex;
  justify-content: space-between;
}

#listTitle {
  border: none;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  height: 37px;
  min-width: 70%;
  outline: none;
  font-size: 25px;
  letter-spacing: -0.2px;
  line-height: 37px;
  text-align: right;
}

.coverV {
  z-index: 999999;
  top: 0px;
  right: 0px;
  position: absolute;
  background-color: rgba(18, 26, 78, 0.23);
  height: 100%;
  width: 100%;
}

.v {
  height: 370px;
  width: 511px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 20px 30px 0 rgba(75, 99, 241, 0.1);
  position: absolute;
  top: 30%;
  right: 40%;
  display: flex;
  flex-direction: column;
}

.vImg {
  position: relative;
  top: 32px;
  margin-bottom: 13px;
  margin-left: auto;
  margin-right: auto;
}

.vFirstRow {
  position: relative;
  top: 32px;
  text-align: center;
  color: #201E75;
  font-size: 25px;
  letter-spacing: -0.2px;
  line-height: 37px;
  margin-left: auto;
  margin-right: auto;
}

.vSecendRow {
  position: relative;
  top: 12px;
  color: #1A1C68;
  font-size: 25px;
  font-weight: bolder;
  margin-left: auto;
  margin-right: auto;
}

.vLastRow {
  position: relative;
  top: 9px;
  color: #151B5A;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
}

.whiteContainer {
  background-color: #ffffff;
  padding: 18px;
  height: calc(100vh - (calc(88px + 8%)));
  overflow-y: scroll;
}

.whiteContainerForViewTask {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 24px;
  height: calc(100vh - (calc(88px + 8%)));
  overflow-y: scroll;
}

.container {
  display: block;
}

.tags {
  margin-right: -4px;
}

.linksToDisplay {
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
}

.ql-toolbar.ql-snow {
  background: #f8f9ff;
}

.category {
  margin: auto 20px;
}

.titleAndCategory {
  display: flex;
  min-height: 50px;
}

.ArrayAdvertising {
  color: #3950D6;
  font-size: 16px;
  font-weight: bold;
}

.ArrayAdvertisingReady {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
}

.buttonAdvertising {
  background-color: #6178FF;
  box-shadow: 0 5px 8px 0 rgba(97, 120, 255, 0.27);
  height: 100%;
  border-radius: 3px;
  margin: 1px;
  width: 150px;
}

.imgPlus {
  margin-left: 15px !important;
  height: 30% !important;
  margin-bottom: 3px;
  background-color: #6178FF;
}

.inputs {
  position: fixed;
  right: 7%;
  display: block;
  right: 11%;
  margin-top: 1%
}

.tagsHeaderForShowTask {
  right: 14px;
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
}

.processLine {
  margin-top: 32px;
  width: 92%;
  position: relative;
}

.processLineInWiewTask {
  display: flex;
}

.processLineChild {
  margin-bottom: 40px;
  margin-right: 73px;
}

.processLineChildForShowTask {
  margin-right: 73px;
  display: flex;
}

.rightDiv {
  display: flex;
}

.textLineNewLine {
  border: none;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 30%;
  margin-left: 6%;
  margin-right: 7%;
  margin-top: -2%;
  height: 20px;
  text-align: right !important
}

.getTextNewLine {
  border: none;
  width: 30%;
  margin-top: 3%;
  margin-left: 6%;
  margin-right: 10%;
  text-align: right !important
}

.textLine {
  border: none;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 30%;
  margin-left: 6%;
  font-size: 18px;
  font-weight: bold;
  margin-right: 2%;
  text-align: right !important
}

.getTextLine {
  border: none;
  width: 30%;
  margin-left: 6%;
  margin-right: 2%;
  text-align: right !important
}

.linkLine {
  border: none;
  margin-left: 9px;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
}

.emptyColForCss {
  min-width: 87%;
}

.CreateProcessEmpty {
  height: 25px;
  width: 25px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
  cursor: no-drop;
}

.CreateProcessEmptyForPlus {
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  min-width: 26px;
  margin-top: 24px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
  margin-left: 20px;
}

.CreateProcessEmptyForPlusChildRow {
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  bottom: 4px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
}

.CreateProcessEmptyForPlusChildRow #plus {
  /*color: #121A4E*/
}

.CreateProcessCreated {
  cursor: no-drop;
  height: 25px;
  width: 25px;
  border: 1px solid #BEC7E5;
  background-color: #DEE5FE;
}

.CreateProcessCreatedForShow {
  cursor: pointer;
  height: 25px;
  width: 25px;
  border: 1px solid #BEC7E5;
  background-color: #DEE5FE;
}

.CreateProcessCheckBox {
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  min-width: 25px;
  margin-left: 10px;
  border: 1px solid #BEC7E5;
  background-color: #DEE5FE;
}

.ProcessCheckBox {
  cursor: no-drop;
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  position: relative;
  top: 56px;
  right: 10px;
  border: 1px solid #BEC7E5;
  background-color: #DEE5FE;
}

.ProcessCheckBoxEmpty {
  position: relative;
  top: 56px;
  right: 10px;
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
  cursor: no-drop;
}

.CheckBoxForUser {
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  min-width: 25px;
  margin-left: 10px;
  border: 1px solid #536AEE;
  background-color: #6178FF;
  position: relative;
}

.CheckBoxForUser::after {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 25px;
  min-width: 25px;
  height: 25px;
  content: "";
  background-image: url('../../ui/images/checkBox.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.cards {
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
}

.linksHeader {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 7px;
}

.tox-notification__body {
  display: none !important;
}

.tox-statusbar__text-container {
  display: none !important;
}

.tox-statusbar {
  display: none !important;
}

.tox .tox-edit-area {
  display: contents !important;
}

body #tinymce .mce-content-body {
  background: #3950D6;
  margin-top: -18px;
}

.mce-content {
  width: 100%;
}

.mce-content-body p {
  margin: 0;
  min-height: 25px;
}

.mce-content-body {
  position: relative;
  outline: none !important;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 100%;
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: right;
  margin-top: 36px;
}

.tox .tox-edit-area__iframe {
  height: unset !important;
  position: unset !important;
}

.tox-toolbar-dock-fadein {
  max-width: 150px;
}

.tox-editor-container {
  /* position: relative; */
}

.tox .tox-toolbar {
  position: sticky;
}

.tox-notification__icon {
  display: none !important;
}

.tox-collection__group {
  display: none !important;
}

.tox-notification {
  display: none !important;
}

.tox-notification__dismiss {
  display: none !important;
}

.fakeInput {
  min-width: 200px;
  min-height: 100px;
  border: #121A4E 1px solid
}

#inputForEssence {
  margin-top: -24px;
  width: 100%;
  min-height: 100px;
  border-left: none
}

.highlight {
  background: #536AEE
}

.myHr {
  border: 0.5px solid black
}

.filesHeader {
  font-size: 16px;
  font-weight: bold;
}

.CardBox {
  box-sizing: border-box;
  height: 72px;
  width: 227px;
  border: 1px solid #DADDEE;
  margin: 10px;
  position: relative;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
}

.forImg {
  height: 32px;
  margin-left: 7.8%;
}

.createLink {
  background-color: #6178ff;
  right: 80%;
}

.headerOfCard {
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: right;
  text-overflow: ellipsis;
  width: 83%;
  white-space: nowrap;
  overflow: hidden;
}

.tipeOfFile {
  color: #C6C4DF;
  font-size: 14px;
  font-weight: bold;
}

.addInput {
  height: 37px;
  width: 143px;
  background-color: #6178FF;
  box-shadow: 0 5px 8px 0 rgba(97, 120, 255, 0.27);
  padding-right: 24px;
  padding-top: 8px;
  display: flex;
}

.morLinx {
  height: 37px;
  width: 150px;
  background-color: #6178FF;
  box-shadow: 0 5px 8px 0 rgba(97, 120, 255, 0.27);
}

.addLinksText {
  height: 21px;
  width: 95px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.89px;
  line-height: 21px;
  text-align: right;
}

#addFile {
  height: 21px;
  width: 91px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.89px;
  line-height: 21px;
  margin-right: 1px;
  text-align: right;
  color: #FFFFFF;
}

#plus {
  height: 10px;
  width: 10px;
  color: #6178FF;
  margin-right: 7px;
  margin-top: -1px;
}

#plusForChildRow {
  height: 7px;
  width: 7px;
  color: #6178FF;
  margin-right: 24%;
  margin-bottom: 10px;
}

.editList {
  height: 21px;
  width: 73px;
  color: #9A9CB7;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 21px;
  text-align: right
}

.editListForShow {
  height: 21px;
  width: 73px;
  color: #9A9CB7;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 21px;
  text-align: right
}

.editListForShow:hover {
  height: 21px;
  width: 73px;
  color: #6178ff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 21px;
  text-align: right
}

.editListButton {
  box-sizing: border-box;
  height: 36px;
  width: 122px;
  margin: 4px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
}

.editListButtonForShow {
  box-sizing: border-box;
  width: 122px;
  height: 36px;
  color: #9A9CB7;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  margin-right: 24px;
  display: flex;
  justify-content: space-between;
  line-height: 1.1;
  padding: 0px 11px 0px 11px;
}

.editListButtonForShowOver {
  box-sizing: border-box;
  width: 122px;
  height: 36px;
  color: #9A9CB7;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  margin-right: 24px;
  display: flex;
  justify-content: space-between;
  line-height: 1.1;
  padding: 0px 11px 0px 11px;
}

.editListButtonForShow:hover {
  color: #6178ff;
  border: 1px solid #6178ff;
}

.editListButtonForShow:hover svg g g {
  fill: #6178ff;
}

button:focus {
  outline: none;
}

.child {
  display: grid;
  margin-right: 31px;
}

#category {
  border: none;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  height: 37px;
  width: 50%;
  font-size: 16px;
  letter-spacing: -0.13px;
  text-align: right;
}

.mainLogo {
  position: absolute;
  left: 50%;
  top: 81%;
  transform: translate(-50%, -13%);
}

.whiteImg {
  margin: 23%
}

.logos {
  margin-right: auto;
  justify-content: baseline;
  display: flex;
  float: left;
}

.frameForLogo {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  margin: 1px;
  margin-left: 11px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
}

.frameForLogoForShoe {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  margin: 1px;
  margin-left: 11px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
}

.frameForLogoForShoe-is-not-isStaff {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  margin: 1px;
  margin-left: 11px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
}

.frameForLogoForShoe:hover {
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27)
}

.selectcategory {
}

::-webkit-scrollbar {
  width: 0px;
}

.CreateProcessCheckBoxAfterUserWrites {
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  min-width: 26px;
  border: 1px solid #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
  margin-top: 24px;
  margin-left: 20px;
}

.class-first-child-plus {
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  bottom: 4px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
}

.flex-and-column {
  display: flex;
  flex-direction: column;
}

.delete-x-btn {
  position: absolute;
  right: 205px;
  bottom: 55px;
}

.class-task-link {
  margin: 6px 0px 6px 0px;
}
