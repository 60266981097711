@import url(https://fonts.googleapis.com/css?family=Heebo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Heebo&display=swap);

/*# sourceMappingURL=index.css.map */
/* @import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900&display=swap'); */

/* body {
  margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  background: unset;
  border: unset;
  cursor: pointer;
  outline:0;
}
input {
  outline:0;
}
textarea {
  outline:0;
}
select {
  outline: 0;
}

.pointer {
  cursor: pointer;
} */

input,
button,
textarea,
select {
    outline: unset;
}

button {
    border: unset;
    background: unset;
    cursor: pointer;
}

iframe {
    display: none;
}

@font-face {
    font-family: 'Awesome-Brands';
    src: url(/static/media/Brands-Regular-400.c8c8b2c1.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome';
    src: url(/static/media/Pro-Thin-100.2adb30c7.otf) format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome';
    src: url(/static/media/Pro-Light-300.a0531076.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome';
    src: url(/static/media/Pro-Regular-400.33efb68e.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome';
    src: url(/static/media/Pro-Solid-900.fc18198f.otf) format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Awesome-Duotone';
    src: url(/static/media/Duotone-Solid-900.1d72d58d.otf) format('opentype');
    font-weight: 900;
    font-style: normal;
}
.class-password-main {
    display: flex;
    position: relative;
}
.login-password-input{
    width: 100%;
    height: 35px;
    border: unset;
    border-bottom: 1px solid rgba(18,26,78,0.6);
    color: #121A4E;
    padding: 10px 0;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    background-repeat: no-repeat;
    background-position: left;
    margin-top: 28px;
    /* font-weight: 600; */
}
.class-password-label-login-sm {
    position: absolute;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: 600;
    top: 8px;
}
.class-password-incorrect {
    margin-top: 3px;
    color: #FF8B90;	
    font-family: 'Heebo', sans-serif;
    font-size: 12px;	
    letter-spacing: -0.1px;	
}
.class-input-images {
    border-bottom: 1px solid rgba(18,26,78,0.6);
    padding-top: 30px;
}
/* .class-check-logo {
    border-bottom: 2px solid #07DCCD;
} */

.class-text-main{
    position: relative
}
.login-text-input{
    width: 100%;
    height: 35px;
    border: unset;
    border-bottom: 1px solid rgba(18,26,78,0.6);
    color: #121A4E;
    /*padding: 10px 0;*/
    font-family: 'Heebo', sans-serif;
    margin: 28px 0;
    font-size: 16px;
    /* font-weight: 600; */
}
.class-text-label-login-sm {
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    top: 7px;
    font-family: 'Heebo', sans-serif;
}

.yesod-modal-layer{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    background-color: rgba(255,255,255,0.8)
}
.yesod-modal{
    background-color: #FFFFFF;
    height: 564px;	
    width: 950px;
    box-shadow: 0 20px 30px 0 rgba(75,99,241,0.1);
    border-radius: 8px;
    float: none;
    margin-top: 106px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
}
.modal-logoUp {
    padding: 5px;
}
.modal-logo {
    height: 80px;	
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    margin-top: -80px;
}
.modal-to-system-text {
	color: #121A4E;
	font-family: 'Heebo', sans-serif;
	font-size: 25px;
	font-weight: bold;
    line-height: 37px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 25px;
}
.gutter-box {
    margin-right: 10px;
    margin-left: 10px;
}
.modal-details-header {
	color: #121A4E;
	font-family: 'Heebo', sans-serif;
	font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
}
.modal-btn {
    height: 45px;
    width: 422px;
    background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    border: unset;
    color: #FFFFFF;
    font-family: 'Heebo', sans-serif;	
    font-size: 16px;	
    font-weight: bold;
    letter-spacing: 1.19px;
}
.modal-checkbox {
    width: 24px;
    height: 24px;
}
.custom-control {
    padding-right: 2rem;
    margin-top: 10px;
    color: #121A4E;	
    font-family: 'Heebo', sans-serif;	
    font-size: 16px;
    letter-spacing: -0.13px;
}

.custom-control-label::before {
    background-color: #DEE5FE;
    border: 1px solid #BEC7E5;
    border-radius: 0px;
}
.custom-control-label::before, .custom-control-label::after {
    top: 0;
    border: 1px solid #BEC7E5;
    right: -2rem;
    width: 24px;
    height: 24px;
    border-radius: 0px;
}

.background-login {
  width: 100%;
  height: 100vh;
  background: #EEF2FE url(/static/media/loginBackground.909f139d.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  display: flex;
  flex-direction: column;
}

.login-details {
  height: 472px;
  width: 486px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 20px 30px 0 rgba(75, 99, 241, 0.1);
  margin-top: -20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.login-to-system-text {
  color: #121A4E;
  font-family: 'Heebo', sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  margin-top: -44px;
  margin-bottom: 32px;
}

.class-logo-login-area {
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -78px;
  margin-bottom: 90px;
}

.login-btn {
  height: 45px;
  width: 100%;
  background-color: #6178FF;
  border: unset;
  box-shadow: 0 5px 8px 0 rgba(97, 120, 255, 0.27);
  margin-top: auto;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Heebo', sans-serif;
  cursor: pointer;
}

.class-first-time-login {
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
  color: #121A4E;
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  letter-spacing: -0.13px;
}

.class-login-logoUp {
  padding: 5px;
}

.yesod-login-a {
  font-weight: bold;
  color: #6178FF;
  text-decoration: underline;
}

.yesod-primary-btn {
    background-color: #6178FF;	
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    color: #FFFFFF;	
    font-family: 'Heebo', sans-serif;
    font-size: 16px;	
    font-weight: bold;	
    border: unset;
}
.registration_page{
  width: 100%;
  overflow: auto;
}
.create_form{
  padding: 30px;
  background-color: white;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.PhoneInputInput {
  direction: rtl;
}
#cu_phone .div-create-user {
    height: 332px;
    width: 1524px;
    border-radius: 3px;
    background-color: #FFFFFF;
    padding: 30px;
}
#group{

}
.create-user-header-a {
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.13px;
    margin-bottom: 19px;
}
.create-user-header-b {
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.13px;
    margin-top: 31px;
}
.sort-input-width {
    padding-left: 53px;
}
.creat-user-state {
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.12px;
    margin-top: 104px;
    margin-bottom: 26px;
}
.create-user-btn {
    margin-top: 26px;
    display: flex;
}
.create-user-btn-cancel {
    margin-top: 12px;
    margin-right: 20px;
    color: #6178FF;
}
.main-div-creat-user {
    background-color: #F8F9FF;
    padding-right: 30px;
}

.CreateTaskPage {
  background-color: #f8f9ff;
  padding: 2%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
}

.tox-tinymce {
  width: 900px;
}

.childRow {
  margin-right: 46px;
  width: 95%;
}

.whiteContainer > .childRow {
  margin-right: 55px;
  margin-top: 2px;
  width: 86%;
}

.childRow .tox-tinymce {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px black solid !important;
  margin-bottom: 7px;
  width: 74%;
}

.showProcess {
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: right;
}

.ShowTask {
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  word-wrap: break-word;
}

.ShowTaskChild {
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  word-wrap: break-word;
}

.processLine .tox {
  text-align: right !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px black solid !important;
  margin-bottom: 7px
}

.createHeader {
  height: 44px;
  font-size: 30px;
  min-width: 96px;
  letter-spacing: -0.24px;
}

.tagName {
  font-size: 12px;
  font-weight: bold;
  margin-top: 2%
}

.tagsHeaderForShow {
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
  margin-bottom: 5px;
}

.tagsHeader {
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
}

.NewBolder {
  background-color: #536AEE
}

#maintaskEssence {
  width: 70%;
  display: inline-block
}

.essence {
  font-size: 16px;
  word-wrap: break-word;
  color: #141C5B;
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: right
}

.taskEssence {
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
  margin-bottom: 1%;
  margin-top: 4%;
}

.myLine {
  position: relative;
  top: 60px;
  right: 54px;
  height: 1px;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 500px;
}

.tagsiHeader {
  height: 18px;
  margin-top: 14px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
}

.tagsHeaderCategory {
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: -0.1px;
}

.workProcess {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
  margin-top: 24px;
}

.draftSave {
  font-size: 14px;
  margin: auto 7px;
}

.header {
  height: 18px;
  width: 82px;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
}

#BoldFakeIcon {
  font-size: 26px;
  font-weight: bolder;
  position: relative;
  right: 10px;
}

#essenceHandleChange {
  min-width: 60%;
  min-height: 100px;
  border: black 1px solid;
}

.headerDiv {
  display: flex;
  padding: 0 0 10px;
  justify-content: space-between;
  position: relative;
}

.dubleHeader {
  display: flex;
  justify-content: space-between;
}

#listTitle {
  border: none;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  height: 37px;
  min-width: 70%;
  outline: none;
  font-size: 25px;
  letter-spacing: -0.2px;
  line-height: 37px;
  text-align: right;
}

.coverV {
  z-index: 999999;
  top: 0px;
  right: 0px;
  position: absolute;
  background-color: rgba(18, 26, 78, 0.23);
  height: 100%;
  width: 100%;
}

.v {
  height: 370px;
  width: 511px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 20px 30px 0 rgba(75, 99, 241, 0.1);
  position: absolute;
  top: 30%;
  right: 40%;
  display: flex;
  flex-direction: column;
}

.vImg {
  position: relative;
  top: 32px;
  margin-bottom: 13px;
  margin-left: auto;
  margin-right: auto;
}

.vFirstRow {
  position: relative;
  top: 32px;
  text-align: center;
  color: #201E75;
  font-size: 25px;
  letter-spacing: -0.2px;
  line-height: 37px;
  margin-left: auto;
  margin-right: auto;
}

.vSecendRow {
  position: relative;
  top: 12px;
  color: #1A1C68;
  font-size: 25px;
  font-weight: bolder;
  margin-left: auto;
  margin-right: auto;
}

.vLastRow {
  position: relative;
  top: 9px;
  color: #151B5A;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
}

.whiteContainer {
  background-color: #ffffff;
  padding: 18px;
  height: calc(100vh - (calc(88px + 8%)));
  overflow-y: scroll;
}

.whiteContainerForViewTask {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 24px;
  height: calc(100vh - (calc(88px + 8%)));
  overflow-y: scroll;
}

.container {
  display: block;
}

.tags {
  margin-right: -4px;
}

.linksToDisplay {
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
}

.ql-toolbar.ql-snow {
  background: #f8f9ff;
}

.category {
  margin: auto 20px;
}

.titleAndCategory {
  display: flex;
  min-height: 50px;
}

.ArrayAdvertising {
  color: #3950D6;
  font-size: 16px;
  font-weight: bold;
}

.ArrayAdvertisingReady {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
}

.buttonAdvertising {
  background-color: #6178FF;
  box-shadow: 0 5px 8px 0 rgba(97, 120, 255, 0.27);
  height: 100%;
  border-radius: 3px;
  margin: 1px;
  width: 150px;
}

.imgPlus {
  margin-left: 15px !important;
  height: 30% !important;
  margin-bottom: 3px;
  background-color: #6178FF;
}

.inputs {
  position: fixed;
  right: 7%;
  display: block;
  right: 11%;
  margin-top: 1%
}

.tagsHeaderForShowTask {
  right: 14px;
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
}

.processLine {
  margin-top: 32px;
  width: 92%;
  position: relative;
}

.processLineInWiewTask {
  display: flex;
}

.processLineChild {
  margin-bottom: 40px;
  margin-right: 73px;
}

.processLineChildForShowTask {
  margin-right: 73px;
  display: flex;
}

.rightDiv {
  display: flex;
}

.textLineNewLine {
  border: none;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 30%;
  margin-left: 6%;
  margin-right: 7%;
  margin-top: -2%;
  height: 20px;
  text-align: right !important
}

.getTextNewLine {
  border: none;
  width: 30%;
  margin-top: 3%;
  margin-left: 6%;
  margin-right: 10%;
  text-align: right !important
}

.textLine {
  border: none;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 30%;
  margin-left: 6%;
  font-size: 18px;
  font-weight: bold;
  margin-right: 2%;
  text-align: right !important
}

.getTextLine {
  border: none;
  width: 30%;
  margin-left: 6%;
  margin-right: 2%;
  text-align: right !important
}

.linkLine {
  border: none;
  margin-left: 9px;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
}

.emptyColForCss {
  min-width: 87%;
}

.CreateProcessEmpty {
  height: 25px;
  width: 25px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
  cursor: no-drop;
}

.CreateProcessEmptyForPlus {
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  min-width: 26px;
  margin-top: 24px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
  margin-left: 20px;
}

.CreateProcessEmptyForPlusChildRow {
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  bottom: 4px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
}

.CreateProcessEmptyForPlusChildRow #plus {
  /*color: #121A4E*/
}

.CreateProcessCreated {
  cursor: no-drop;
  height: 25px;
  width: 25px;
  border: 1px solid #BEC7E5;
  background-color: #DEE5FE;
}

.CreateProcessCreatedForShow {
  cursor: pointer;
  height: 25px;
  width: 25px;
  border: 1px solid #BEC7E5;
  background-color: #DEE5FE;
}

.CreateProcessCheckBox {
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  min-width: 25px;
  margin-left: 10px;
  border: 1px solid #BEC7E5;
  background-color: #DEE5FE;
}

.ProcessCheckBox {
  cursor: no-drop;
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  position: relative;
  top: 56px;
  right: 10px;
  border: 1px solid #BEC7E5;
  background-color: #DEE5FE;
}

.ProcessCheckBoxEmpty {
  position: relative;
  top: 56px;
  right: 10px;
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
  cursor: no-drop;
}

.CheckBoxForUser {
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  min-width: 25px;
  margin-left: 10px;
  border: 1px solid #536AEE;
  background-color: #6178FF;
  position: relative;
}

.CheckBoxForUser::after {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 25px;
  min-width: 25px;
  height: 25px;
  content: "";
  background-image: url(/static/media/checkBox.2af2071a.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.cards {
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
}

.linksHeader {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 7px;
}

.tox-notification__body {
  display: none !important;
}

.tox-statusbar__text-container {
  display: none !important;
}

.tox-statusbar {
  display: none !important;
}

.tox .tox-edit-area {
  display: contents !important;
}

body #tinymce .mce-content-body {
  background: #3950D6;
  margin-top: -18px;
}

.mce-content {
  width: 100%;
}

.mce-content-body p {
  margin: 0;
  min-height: 25px;
}

.mce-content-body {
  position: relative;
  outline: none !important;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 100%;
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: right;
  margin-top: 36px;
}

.tox .tox-edit-area__iframe {
  height: unset !important;
  position: unset !important;
}

.tox-toolbar-dock-fadein {
  max-width: 150px;
}

.tox-editor-container {
  /* position: relative; */
}

.tox .tox-toolbar {
  position: -webkit-sticky;
  position: sticky;
}

.tox-notification__icon {
  display: none !important;
}

.tox-collection__group {
  display: none !important;
}

.tox-notification {
  display: none !important;
}

.tox-notification__dismiss {
  display: none !important;
}

.fakeInput {
  min-width: 200px;
  min-height: 100px;
  border: #121A4E 1px solid
}

#inputForEssence {
  margin-top: -24px;
  width: 100%;
  min-height: 100px;
  border-left: none
}

.highlight {
  background: #536AEE
}

.myHr {
  border: 0.5px solid black
}

.filesHeader {
  font-size: 16px;
  font-weight: bold;
}

.CardBox {
  box-sizing: border-box;
  height: 72px;
  width: 227px;
  border: 1px solid #DADDEE;
  margin: 10px;
  position: relative;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
}

.forImg {
  height: 32px;
  margin-left: 7.8%;
}

.createLink {
  background-color: #6178ff;
  right: 80%;
}

.headerOfCard {
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: right;
  text-overflow: ellipsis;
  width: 83%;
  white-space: nowrap;
  overflow: hidden;
}

.tipeOfFile {
  color: #C6C4DF;
  font-size: 14px;
  font-weight: bold;
}

.addInput {
  height: 37px;
  width: 143px;
  background-color: #6178FF;
  box-shadow: 0 5px 8px 0 rgba(97, 120, 255, 0.27);
  padding-right: 24px;
  padding-top: 8px;
  display: flex;
}

.morLinx {
  height: 37px;
  width: 150px;
  background-color: #6178FF;
  box-shadow: 0 5px 8px 0 rgba(97, 120, 255, 0.27);
}

.addLinksText {
  height: 21px;
  width: 95px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.89px;
  line-height: 21px;
  text-align: right;
}

#addFile {
  height: 21px;
  width: 91px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.89px;
  line-height: 21px;
  margin-right: 1px;
  text-align: right;
  color: #FFFFFF;
}

#plus {
  height: 10px;
  width: 10px;
  color: #6178FF;
  margin-right: 7px;
  margin-top: -1px;
}

#plusForChildRow {
  height: 7px;
  width: 7px;
  color: #6178FF;
  margin-right: 24%;
  margin-bottom: 10px;
}

.editList {
  height: 21px;
  width: 73px;
  color: #9A9CB7;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 21px;
  text-align: right
}

.editListForShow {
  height: 21px;
  width: 73px;
  color: #9A9CB7;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 21px;
  text-align: right
}

.editListForShow:hover {
  height: 21px;
  width: 73px;
  color: #6178ff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 21px;
  text-align: right
}

.editListButton {
  box-sizing: border-box;
  height: 36px;
  width: 122px;
  margin: 4px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
}

.editListButtonForShow {
  box-sizing: border-box;
  width: 122px;
  height: 36px;
  color: #9A9CB7;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  margin-right: 24px;
  display: flex;
  justify-content: space-between;
  line-height: 1.1;
  padding: 0px 11px 0px 11px;
}

.editListButtonForShowOver {
  box-sizing: border-box;
  width: 122px;
  height: 36px;
  color: #9A9CB7;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  margin-right: 24px;
  display: flex;
  justify-content: space-between;
  line-height: 1.1;
  padding: 0px 11px 0px 11px;
}

.editListButtonForShow:hover {
  color: #6178ff;
  border: 1px solid #6178ff;
}

.editListButtonForShow:hover svg g g {
  fill: #6178ff;
}

button:focus {
  outline: none;
}

.child {
  display: grid;
  margin-right: 31px;
}

#category {
  border: none;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  height: 37px;
  width: 50%;
  font-size: 16px;
  letter-spacing: -0.13px;
  text-align: right;
}

.mainLogo {
  position: absolute;
  left: 50%;
  top: 81%;
  -webkit-transform: translate(-50%, -13%);
          transform: translate(-50%, -13%);
}

.whiteImg {
  margin: 23%
}

.logos {
  margin-right: auto;
  justify-content: baseline;
  display: flex;
  float: left;
}

.frameForLogo {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  margin: 1px;
  margin-left: 11px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
}

.frameForLogoForShoe {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  margin: 1px;
  margin-left: 11px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
}

.frameForLogoForShoe-is-not-isStaff {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  margin: 1px;
  margin-left: 11px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
}

.frameForLogoForShoe:hover {
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27)
}

.selectcategory {
}

::-webkit-scrollbar {
  width: 0px;
}

.CreateProcessCheckBoxAfterUserWrites {
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  min-width: 26px;
  border: 1px solid #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
  margin-top: 24px;
  margin-left: 20px;
}

.class-first-child-plus {
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  bottom: 4px;
  border: 1px dashed #BEC7E5;
  background-color: rgba(222, 229, 254, 0.22);
}

.flex-and-column {
  display: flex;
  flex-direction: column;
}

.delete-x-btn {
  position: absolute;
  right: 205px;
  bottom: 55px;
}

.class-task-link {
  margin: 6px 0px 6px 0px;
}

.rectangle-btn {
  margin-right: 10px;
  box-sizing: border-box;
  height: 45px;
  width: 146px;
  border-radius: 51px;
  background-color: #6178ff;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.11px;
  cursor: pointer;
}

.rectangle-btn:hover {
  border: 2px solid #6178FF;
}

.button-text{
  padding-right: 5px;
}

.copy-icon::before{
  font-family: Awesome;
  font-size: 15px;
  font-weight: 400;
  content: '\f0c5';
}


.react-add-to-calendar__dropdown ul {
  list-style: none;
  margin: 0;
}
.react-add-to-calendar__dropdown {
  position: absolute;
  /*top: 30px;*/
  width: 10%;
  padding: 5px 0 5px 8px;
  box-shadow: 1px 3px 6px rgb(0 0 0 / 15%);
  border: 1px solid #a8a8a8;
  background-color: #fff;
  text-align: left;
}

.react-add-to-calendar__button--light {
  background-color: #fff;
}

body, html {
  height: 100%;
  text-align: start;
  font-family: 'Heebo', sans-serif;
}
.main-container {
  color: #121A4E;
  /* height: 100vh; */
  padding: 0 84px 35px;
  font-family: 'Heebo', sans-serif;
  width: 97%;
  margin: auto;
  -ms-overflow-style: none;
}
.main-container::-webkit-scrollbar {
  display: none;
}


.main-container .headerDiv {
  display: flex;
  padding: 0 0 10px;
  justify-content: space-between;
  position: relative;
  margin-bottom: 33px;

}

.main-container .sticky {
  background-color: #F8F9FF;
  /* height: 75px; */
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top:0px;
  left: 0rem;
  right: 13rem;
  z-index: 999;
  padding-top: 35px;
}

.main-container .createHeader {
  font-size: 35px;
  font-weight: bold;
  letter-spacing: -0.28px;
  line-height: 40px;
}
.wrap-logo-img{
  position: relative;
}

.main-container .logo {
  margin-top: -33px; 
}
.main-container .lock{
  position: relative;
  /* right: -15px; */
  top: 3px;
  margin-right: -10px;
}

.description {
  margin-top: 33px;
  width: 100%;
  box-shadow: 0 6px 17px 0 rgba(18, 26, 78, 0.08);
  margin-bottom: 20px;
  padding-right: 20px;
  background-color: #FFFFFF;
}

.description .title {
  color: #121A4E;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 37px;

}

.description .body {
  color: #121A4E;
  font-size: 18px;
  letter-spacing: -0.14px;
  line-height: 27px;
  padding: 11px 0px 26px 22%;
}

.event-icon {
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  margin: 1px;
  margin-left: 10px;
  border: 1px solid #6178FF;
  border-radius: 3px;
  /*background-color: #F8F9FF;*/
  cursor: pointer;
}

.event-icon-disabled {
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  margin: 1px;
  margin-left: 10px;
  border: 1px solid #adb5bd;
  border-radius: 3px;
  /* background-color: #F8F9FF; */
}

.edit-follow {
  color: #121A4E;
  font-family: Heebo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.13px;
  line-height: 24px;
}

#edit-title {
  color: #121A4E;
  font-size: 25px;
  letter-spacing: -0.2px;
  line-height: 37px;
}

.event-icon:hover {
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27)
}

.main-container table {
  width: 100%;
  color: #121A4E;
  font-size: 17px;
  letter-spacing: -0.14px;
  line-height: 25px;
}

.table {
  border: none;
  color: #4D89EA;
  table-layout: fixed;
}

.table tr th {
  border: none;
}

.table td {
  vertical-align: middle;
  text-align: center;
}

.table .choose td {
  box-sizing: border-box;
  /*height: 157px;*/
  border: 1px solid #6178FF;
  border-top: 0.114rem solid #6178FF;
  vertical-align: top;

}
.box-shadow  {
  box-shadow: 0 4px 20px 0 rgba(18,26,78,0.14);
}

.table #td-child {
  text-align: right;
  padding: 0px;
  height: 100%;
  /*border: 1px solid #DADDEE*/
}

#forEditor {
  padding: 0;
  /*border: 1px solid #DADDEE;*/
  /*min-width: -webkit-max-content;*/
  min-block-size: -webkit-max-content;
  /*min-height: 1vw;*/


}
#forEditor img{
  display:block; width:100%; height:auto;
}


#editor-container {
  /*height: 375px;*/
}

.table td .quill .editor-wrapper {
  border: none;
  /*-webkit-box-ordinal-group: 1;*/
  /*-moz-box-ordinal-group: 1;*/
  /*-ms-flex-order: 1;*/
  /*-webkit-order: 1;*/
  /*order: 1;*/
}

.table td .quill .ql-container {
  border: none;
}
.desc .quill .ql-container.ql-snow {
/*border: none;*/
  color: #121A4E;
}

.table td .quill .ql-toolbar {
  border: none;
  border-bottom: 1px solid #ccc;
  text-align: start;
  /*-webkit-box-ordinal-group: 2;*/
  /*-moz-box-ordinal-group: 2;*/
  /*-ms-flex-order: 2;*/
  /*-webkit-order: 2;*/
  /*order: 2;*/
}


.table #td-child .d-inline-flex .right {
  width: 45px;
  margin-left: 1rem;
}

.main-container .userIcon {
  height: 26.6px;
  width: 26.6px;
  background-color: rgba(18, 26, 78, 0.1);
  border-radius: 50%;
  font-size: 10.5px;
  font-weight: bold;
}

.done {
  color: #07DCCD;
  /*background-color: rgba(7, 220, 205, 0.06);*/
}

.inProgress {
  color: #B5B4D2;
}

.progress {
  height: 12px;
  width: 295px;
  border-radius: 6px;
  background-color: #E6E9F6;
  box-shadow: inset 0 0 6px 0 rgba(18, 26, 78, 0.06);
}

.progress .progress-status {
  height: 12px;
  border-radius: 6px;
  background-color: #07DCCD;
}
.main-container [id^='extensions']{
  width: 242px;
  box-sizing: border-box;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.22);
  /*position: relative;*/

}

.main-container-print [id^='extensions']{
  width: 242px;
  box-sizing: border-box;
}


[id^='extensions'] .edit {
  height: 88px;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.22);
}

[id^='extensions'] .show {
  cursor: pointer;
  height: 72px;
}

.title {
  line-height: 25px;
  max-width: 160px;
  font-size: 17px;
  letter-spacing: -0.14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sub-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.13px;
  line-height: 24px;
}
.type {
  color: #B5B4D2;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 21px;
}

[id^='extensions'] .icon {

}

.main-container .TagSelected {
  height: 33px;
  color:#FFFFFF;
  border-radius: 19px;
  background-color: #6178FF;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  margin-left: 9px;
  cursor: pointer;
}
.TagNotSelected {
  height: 33px;
  color: #717695;
  border-radius: 19px;
  background-color: #DADDEE;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  margin-left: 9px;
  cursor: pointer;
}

.main-container .comment {
  color: #121A4E;
  letter-spacing: -0.2px;
  margin-left: 30px;
}

.comment .comment-box {
  width: 100%;

}

.comment .comment-title {
  font-size: 25px;
  font-weight: bold;
}

.comment .comment-box .comment-body {
  opacity: 0.3;
  color: #1A1C68;
  font-size: 17px;
  letter-spacing: -0.14px;
  border-bottom: rgba(18, 26, 78, 0.77) solid 1px;
}

.comment .textarea-container {
  position: relative;
  width: inherit;
  height: 109px;
}

.comment .textarea-container textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #EBEEFB;
  border: none;
  margin-top: 10px;
  font-size: 17px;
  padding-top: 15px;
  padding-right: 15px;
}

.comment .textarea-container div {
  position: absolute;
  bottom: 0;
  left: 0;
}

.comment .textarea-container div > button {
  margin: 0 0 0 10px;
  cursor: pointer;
  box-sizing: border-box;
  height: 36px;
  border: 1px solid #DADDEE;
  border-radius: 3px;
  background-color: #FFFFFF;
}

.fs-16 {
  font-size: 16px;
}

.contextMenu {
  position: fixed;
  background: white;
  box-shadow: 0px 2px 10px #999999;
  z-index: 99999;
}
.contextMenu--option {
  padding: 6px 50px 5px 10px;
  min-width: 160px;
  cursor: default;
  font-size: 12px;
}
.contextMenu--option:hover {
  background: linear-gradient(to top, #555, #333);
  color: white;
}
.contextMenu--option:active {
  color: #e9e9e9;
  background: linear-gradient(to top, #555, #444);
}
.contextMenu--option__disabled {
  color: #999999;
  pointer-events: none;
}
.contextMenu--separator {
  width: 100%;
  height: 1px;
  background: #CCCCCC;
  margin: 0 0 0 0;
}
.users-icon::before {
    font-family: Awesome;
    content: " \f0c0";
    font-weight: 400;
    font-size: 15px;
}

.wrap-overview>.line {
    border-left: 1px solid #D7D7D7;
    padding-left: 9px;
    height: 34px;
    align-self: center;
}

.wrap-overview>.owner-separator {
    border-left: 1px solid #6178ff;
    margin-left: 2px;
    margin-right: 2px;
    height: 24px;
    align-self: center;
}

.overview-text {
    font-size: 15px;
    font-weight: 500;
    line-height: 40px;
    margin-left: 9px;
}

.owner-separator {
    /* margin: 2px; */
}

.shareContainer {
    position: absolute;
    width: 500px;
    background-color: #FFFFFF;
    z-index: 20;
    border-radius: 10px;
    padding: 0 20px;
    margin: 15px 10px 0 0;
    background: #FFFFFF;
    border: 1px solid rgba(190, 199, 229, 0.3);
    box-shadow: 0px 0px 50px rgba(204, 210, 227, 0.45);
}

.sharing-header {
    font-size: 15px;
    font-weight: 700;
    border-bottom: #DBDFF8 solid 1px;
    padding: 20px 0;
    display: flex;
    align-items: center;
}

.wrap-header-icon {
    height: 38px;
    width: 38px;
    background-color: #E7EBFF;
    border-radius: 50%;
    margin: 0 0 0 10px;
}

.header-icon {
    position: relative;
    top: 9px;
    right: 9.5px;
}

.header-icon::before {
    font-family: Awesome;
    content: " \f0c0";
    font-weight: 400;
    font-size: 15px;
    color: #6178FF;
    height: 18px;
    width: 18px;
    display: inline-block;
}

.sub-header {
    margin: 16px 0;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.5;
}

.wrap-input {
    background: #FFFFFF;
    border: 1px solid #6178FF;
    border-radius: 8px;
    height: 40px;
    caret-color: #6178FF;
}

.search-icon {
    vertical-align: middle;
    padding: 12px;
}

.search-icon::before {
    font-family: Awesome;
    content: '\f002';
    font-weight: 900;
    font-size: 16px;
    color: #6178FF;
    vertical-align: middle;
}

.wrap-input input {
    border: none;
    /* margin-right: 41px; */
    line-height: 36px;
}

.shared-user-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 460px;
    overflow-x: hidden;
    padding: 4px 0 0 0;
}

.group-user-wrap {
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.group-user-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.users-header {
    padding: 8px 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
}

.user-row {
    display: flex;
    padding: 5px 0;
    border-bottom: #DBDFF8 solid 1px;
    height: 50px;
    justify-content: space-between;
}

.user-row:hover {
    background-color: #E7EBFF;
    border-radius: 8px;
}

.user-row:last-child {
    border: none;
}

.right-part,
.left-part {
    height: 100%;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.right-part {
    justify-content: start;
}

.left-part {
    justify-content: end;
    padding: 0 12px;
}

.user-logo {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #6178FF;
    margin: 0;
    text-align: center;
}

.shared-user-logo {
    width: 40px;
    height: 40px;
    border: #E7EBFF solid 2px;
    border-radius: 50%;
    background-color: #6178FF;
    margin: 0 0 0 -6px;
    text-align: center;
    /* z-index: 30; */
}

.shared-user-logo.owner {
    margin: 0;
}

.remove-user-icon-wrap {
    position: absolute;
    z-index: 33;
}

.remove-user-circle {
    height: 24px;
    width: 24px;
    border: #DADDEE solid 1px;
    border-radius: 50%;
    background-color: #FFFFFF;
    position: relative;
    right: 25px;
    top: -4px;
    cursor: pointer;
}

.prevent-event {
    pointer-events: none;
}

.remove-user-icon {
    position: relative;
    top: -.5px;
}

.remove-user-icon::before {
    font-family: Awesome;
    font-weight: 400;
    font-size: 12px;
    color: #6178FF;
    content: "\58";
}

.user-logo-text {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 20% 0;
    line-height: 22px;
    text-align: center;
    width: 36px;
    height: 36px;
}

.wrap-logo-text-over {
    position: absolute;
    z-index: 29;
}

.logo-text-over {
    position: relative;
    top: 40px;
    background-color: #E7EBFF;
    border-radius: 10px;
    padding: 5px;
}

.into-user-plus {
    width: 25.5px;
    height: 26px;
    border: 0.6375px dashed #6178FF;
    border-radius: 50%;
}

.plus-icon {
    position: relative;
    top: -23.5px;
    right: 6.5px;
}

.plus-icon::before {
    font-family: Awesome-Duotone;
    font-weight: 900;
    font-size: 15px;
    color: #6178FF;
    content: "\2b";
}

.user-name {}

.user-plus {
    border: #E7EBFF solid 3.75px;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    cursor: pointer;
    z-index: 33;
}

.handler-button {
    text-align: left;
    padding: 15px 0;
}

.logo-text-popup > .popover{
    border: none;
    border-radius: 10px;
}

.logo-text-popup .popover-inner {
    border: 1px solid rgba(190, 199, 229, 0.3);
    box-shadow: 0px 0px 50px rgba(204, 210, 227, 0.45);
    border-radius: 10px;
    border: none;
}

.logo-text-popup .popover-body {
    background-color: #FFFFFF;
    color: #121A4E;
    border: none;
    border-radius: 10px;
    line-height: 5px;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
   max-width: 40%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0,0.2);
  border-radius: 0px;
  box-shadow: rgb(18, 26, 78, 0.2) 0px 4px 20px 0px;
}

.popover-body{
  color: white;
  background-color: #808799;
  font-family: Heebo;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #808799;
}
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #808799;
}

 /* for editor popover*/
.bs-popover-top .arrow::after,
.po-white .bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: white;
}
.bs-popover-bottom .arrow::after,
.po-white .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: white;
}

.po-white .popover-body{
  background-color: white;
}

.popover-body .quill .ql-container.ql-snow {
  border: none;
}

.popover-body .ql-editor {
  direction: rtl;
  text-align: right;
  font-size: 14px !important;
  font-family: 'Heebo', sans-serif;
  color: #121A4E;
  box-sizing: border-box;
  line-height: 1.32;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 5px 5px;
  tab-size: 4;
  -moz-tab-size: 4;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.divider {
  height:20px;
  width:1px;
  background-color:white;
  margin-left:5px}

.callapse{
  font-family: 'Heebo', sans-serif;
  margin-bottom: 20px;
  padding-right: 30px;
  padding-left: 30px;
}

.callapse-close{
  height: 78px;
  cursor: pointer;
  letter-spacing: -0.2px;
  color: #121A4E;
  font-size: 25px;
  font-weight: bold;
  position: relative;
}

.center-v{
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}


.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
    font-size: 13px !important;
}

.ql-size-small,
.ql-size span[data-value="small"]::before {
    font-size: 14px !important;
}

.ql-size-medium,
.ql-size span[data-value="medium"]::before {
    font-size: 18px !important;
}

.ql-size-large,
.ql-size span[data-value="large"]::before {
    font-size: 20px !important;
}
.ql-editor {
  direction: rtl;
  text-align: right;
  font-size: 19px !important;
  font-family: 'Heebo', sans-serif;
  color: #121A4E;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: 0px;
  margin-right: 0.3em;
  text-align: right;
}

.quill-always-hidden-toolbar{
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
.editMode .ql-container.ql-snow {
  border: none;
  background-color: inherit;
}
.ql-container.ql-snow {
  background-color: white;
}

.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
}
.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
   padding:0 2px 0 6px;
  /*padding-bottom: 8px;*/
}

.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
  z-index: 999;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 8px;
}


.option {

}
.option-title {
  height: 27px;
  color: #121A4E;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 22px;
}
.option-description {
  height: 18px;
  color: #727797;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
}

.dropdown-toggle {
  min-width: 100%;
  color: #07DCCD;
  /*[min-width: moz-available];*/
  /*[min-width: webkit-fill-available];*/
  min-width: -moz-max-content;

}
.btn{
  color: #4D89EA;
}
.PhoneInput {
  direction: ltr;
}
.PhoneInputInput{
  border: none;
}

.dropdown .dropdown-btn{
  background-color: inherit;
  border: none;
}

.dropdown-item{
  height: 60px;
  /*width: 196px;*/
  /*transform: scaleX(-1);*/
  /*background-color: #F8F9FF;*/
}



.upload-btn-wrapper {
  position: relative;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.upload-btn{
  height: 45px;
  width: 146px;
  border-radius: 51px;
  background-color: #6178ff;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.11px;
  /* cursor: pointer; */
}

.upload-btn:hover {
  border: 2px solid #6178ff;
}


.mission-array-history-header {
    margin-top: 24.75px;
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.1px;
}
.mission-array-history {
    margin-top: 15px;
    display: flex;
}
.mission-array-history-logo {
    margin-bottom: auto;
}
.mission-array-history-text {
	/*color: #B5B4D2;*/
	font-family: 'Heebo', sans-serif;
	font-size: 14px;
  letter-spacing: -0.11px;
  margin-right: 10px;
}
.mission-array-history-updates {
	color: #B5B4D2;
	font-family: 'Heebo', sans-serif;
	font-size: 14px;
  letter-spacing: -0.11px;
}
.mission-array-update-history-logo {
    margin-left: 10px;
    margin-bottom: auto;
}

ins {
  text-decoration: none;
  background-color: #d4fcbc;
}

del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}

.tr {
  text-align: center;
  /*vertical-align: middle;*/

}
.tr .col {
  vertical-align: middle;
  height: inherit;
}
.active-blue{
  color: #6178FF;
  cursor: pointer;
}
.vertically  {
  margin-top: auto;
  margin-bottom: auto;
}

.num_of_children{
  text-align: center;
  color: #6178FF;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 25px;
  margin-bottom: -3px;
}

.showAll {
  /*height: 24px;*/
  width: 145px;
  color: #6178FF;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: center;
}



/*!** Add a border to the div containing the quill toolbar *!*/
/*.editables-container .editable:not(last-child){*/
/*  border: 1px solid #b94a48;*/
/*}*/

/*!** We dont want any padding here. Try to mimic the container that shows the text before editing *!*/
/*.ql-editor {*/
/*  !*padding: 0;*!*/
/*}*/


/*!** Remove border from quill toolbar, we're adding it to the toolbar container *!*/
/*.ql-toolbar.ql-snow {*/
/*  border: none;*/
/*}*/

/*!** Set styles of quill editor container to mimic the app better *!*/
/*.ql-container.ql-snow {*/
/*  border: none;*/
/*  background-color: inherit;*/
/*}*/

/*!** Reset margins for items to mimic the styles of the quill editor better *!*/
/*p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table {*/
/*  margin: 0;*/
/*}*/


/*!** Apply snow theme to app *!*/

/*.ql-hidden {*/
/*  display: none;*/
/*}*/
/*.ql-out-bottom,*/
/*.ql-out-top {*/
/*  visibility: hidden;*/
/*}*/
/*.ql-tooltip {*/
/*  position: absolute;*/
/*  transform: translateY(10px);*/
/*}*/
/*.ql-tooltip a {*/
/*  cursor: pointer;*/
/*  text-decoration: none;*/
/*}*/
/*.ql-tooltip.ql-flip {*/
/*  transform: translateY(-10px);*/
/*}*/
/*.ql-formats {*/
/*  display: inline-block;*/
/*  vertical-align: middle;*/
/*}*/
/*.ql-formats:after {*/
/*  clear: both;*/
/*  content: '';*/
/*  display: table;*/
/*}*/
/*.ql-stroke {*/
/*  fill: none;*/
/*  stroke: #444;*/
/*  stroke-linecap: round;*/
/*  stroke-linejoin: round;*/
/*  stroke-width: 2;*/
/*}*/
/*.ql-stroke-miter {*/
/*  fill: none;*/
/*  stroke: #444;*/
/*  stroke-miterlimit: 10;*/
/*  stroke-width: 2;*/
/*}*/
/*.ql-fill,*/
/*.ql-stroke.ql-fill {*/
/*  fill: #444;*/
/*}*/
/*.ql-empty {*/
/*  fill: none;*/
/*}*/
/*.ql-even {*/
/*  fill-rule: evenodd;*/
/*}*/
/*.ql-thin,*/
/*.ql-stroke.ql-thin {*/
/*  stroke-width: 1;*/
/*}*/
/*.ql-transparent {*/
/*  opacity: 0.4;*/
/*}*/
/*.ql-direction svg:last-child {*/
/*  display: none;*/
/*}*/
/*.ql-direction.ql-active svg:last-child {*/
/*  display: inline;*/
/*}*/
/*.ql-direction.ql-active svg:first-child {*/
/*  display: none;*/
/*}*/

/*a {*/
/*  text-decoration: underline;*/
/*}*/
/*blockquote {*/
/*  border-left: 4px solid #ccc;*/
/*  margin-bottom: 5px;*/
/*  margin-top: 5px;*/
/*  padding-left: 16px;*/
/*}*/
/*code,*/
/*pre {*/
/*  background-color: #f0f0f0;*/
/*  border-radius: 3px;*/
/*}*/
/*pre {*/
/*  white-space: pre-wrap;*/
/*  margin-bottom: 5px;*/
/*  margin-top: 5px;*/
/*  padding: 5px 10px;*/
/*}*/
/*code {*/
/*  font-size: 85%;*/
/*  padding: 2px 4px;*/
/*}*/
/*pre.ql-syntax {*/
/*  background-color: #23241f;*/
/*  color: #f8f8f2;*/
/*  overflow: visible;*/
/*}*/
/*img {*/
/*  max-width: 100%;*/
/*}*/
/*.ql-picker {*/
/*  color: #444;*/
/*  display: inline-block;*/
/*  float: left;*/
/*  font-size: 14px;*/
/*  font-weight: 500;*/
/*  height: 24px;*/
/*  position: relative;*/
/*  vertical-align: middle;*/
/*}*/
/*.ql-picker-label {*/
/*  cursor: pointer;*/
/*  display: inline-block;*/
/*  height: 100%;*/
/*  padding-left: 8px;*/
/*  padding-right: 2px;*/
/*  position: relative;*/
/*  width: 100%;*/
/*}*/
/*.ql-picker-label::before {*/
/*  display: inline-block;*/
/*  line-height: 22px;*/
/*}*/
/*.ql-picker-options {*/
/*  background-color: #fff;*/
/*  display: none;*/
/*  min-width: 100%;*/
/*  padding: 4px 8px;*/
/*  position: absolute;*/
/*  white-space: nowrap;*/
/*}*/
/*.ql-picker-options .ql-picker-item {*/
/*  cursor: pointer;*/
/*  display: block;*/
/*  padding-bottom: 5px;*/
/*  padding-top: 5px;*/
/*}*/
/*.ql-picker.ql-expanded .ql-picker-label {*/
/*  color: #ccc;*/
/*  z-index: 2;*/
/*}*/
/*.ql-picker.ql-expanded .ql-picker-label .ql-fill {*/
/*  fill: #ccc;*/
/*}*/
/*.ql-picker.ql-expanded .ql-picker-label .ql-stroke {*/
/*  stroke: #ccc;*/
/*}*/
/*.ql-picker.ql-expanded .ql-picker-options {*/
/*  display: block;*/
/*  margin-top: -1px;*/
/*  top: 100%;*/
/*  z-index: 1;*/
/*}*/
/*.ql-color-picker,*/
/*.ql-icon-picker {*/
/*  width: 28px;*/
/*}*/
/*.ql-color-picker .ql-picker-label,*/
/*.ql-icon-picker .ql-picker-label {*/
/*  padding: 2px 4px;*/
/*}*/
/*.ql-color-picker .ql-picker-label svg,*/
/*.ql-icon-picker .ql-picker-label svg {*/
/*  right: 4px;*/
/*}*/
/*.ql-icon-picker .ql-picker-options {*/
/*  padding: 4px 0px;*/
/*}*/
/*.ql-icon-picker .ql-picker-item {*/
/*  height: 24px;*/
/*  width: 24px;*/
/*  padding: 2px 4px;*/
/*}*/
/*.ql-color-picker .ql-picker-options {*/
/*  padding: 3px 5px;*/
/*  width: 152px;*/
/*}*/
/*.ql-color-picker .ql-picker-item {*/
/*  border: 1px solid transparent;*/
/*  float: left;*/
/*  height: 16px;*/
/*  margin: 2px;*/
/*  padding: 0px;*/
/*  width: 16px;*/
/*}*/
/*.ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {*/
/*  position: absolute;*/
/*  margin-top: -9px;*/
/*  right: 0;*/
/*  top: 50%;*/
/*  width: 18px;*/
/*}*/
/*.ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before,*/
/*.ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,*/
/*.ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,*/
/*.ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before,*/
/*.ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,*/
/*.ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {*/
/*  content: attr(data-label);*/
/*}*/
/*.ql-picker.ql-header {*/
/*  width: 98px;*/
/*}*/
/*.ql-picker.ql-header .ql-picker-label::before,*/
/*.ql-picker.ql-header .ql-picker-item::before {*/
/*  content: 'Normal';*/
/*}*/
/*.ql-picker.ql-header .ql-picker-label[data-value="1"]::before,*/
/*.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {*/
/*  content: 'Heading 1';*/
/*}*/
/*.ql-picker.ql-header .ql-picker-label[data-value="2"]::before,*/
/*.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {*/
/*  content: 'Heading 2';*/
/*}*/
/*.ql-picker.ql-header .ql-picker-label[data-value="3"]::before,*/
/*.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {*/
/*  content: 'Heading 3';*/
/*}*/
/*.ql-picker.ql-header .ql-picker-label[data-value="4"]::before,*/
/*.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {*/
/*  content: 'Heading 4';*/
/*}*/
/*.ql-picker.ql-header .ql-picker-label[data-value="5"]::before,*/
/*.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {*/
/*  content: 'Heading 5';*/
/*}*/
/*.ql-picker.ql-header .ql-picker-label[data-value="6"]::before,*/
/*.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {*/
/*  content: 'Heading 6';*/
/*}*/
/*.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {*/
/*  font-size: 2em;*/
/*}*/
/*.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {*/
/*  font-size: 1.5em;*/
/*}*/
/*.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {*/
/*  font-size: 1.17em;*/
/*}*/
/*.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {*/
/*  font-size: 1em;*/
/*}*/
/*.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {*/
/*  font-size: 0.83em;*/
/*}*/
/*.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {*/
/*  font-size: 0.67em;*/
/*}*/
/*.ql-picker.ql-font {*/
/*  width: 108px;*/
/*}*/
/*.ql-picker.ql-font .ql-picker-label::before,*/
/*.ql-picker.ql-font .ql-picker-item::before {*/
/*  content: 'Sans Serif';*/
/*}*/
/*.ql-picker.ql-font .ql-picker-label[data-value=serif]::before,*/
/*.ql-picker.ql-font .ql-picker-item[data-value=serif]::before {*/
/*  content: 'Serif';*/
/*}*/
/*.ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,*/
/*.ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {*/
/*  content: 'Monospace';*/
/*}*/
/*.ql-picker.ql-font .ql-picker-item[data-value=serif]::before {*/
/*  font-family: Georgia, Times New Roman, serif;*/
/*}*/
/*.ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {*/
/*  font-family: Monaco, Courier New, monospace;*/
/*}*/
/*.ql-picker.ql-size {*/
/*  width: 98px;*/
/*}*/
/*.ql-picker.ql-size .ql-picker-label::before,*/
/*.ql-picker.ql-size .ql-picker-item::before {*/
/*  content: 'Normal';*/
/*}*/
/*.ql-picker.ql-size .ql-picker-label[data-value=small]::before,*/
/*.ql-picker.ql-size .ql-picker-item[data-value=small]::before {*/
/*  content: 'Small';*/
/*}*/
/*.ql-picker.ql-size .ql-picker-label[data-value=large]::before,*/
/*.ql-picker.ql-size .ql-picker-item[data-value=large]::before {*/
/*  content: 'Large';*/
/*}*/
/*.ql-picker.ql-size .ql-picker-label[data-value=huge]::before,*/
/*.ql-picker.ql-size .ql-picker-item[data-value=huge]::before {*/
/*  content: 'Huge';*/
/*}*/
/*.ql-picker.ql-size .ql-picker-item[data-value=small]::before {*/
/*  font-size: 10px;*/
/*}*/
/*.ql-picker.ql-size .ql-picker-item[data-value=large]::before {*/
/*  font-size: 18px;*/
/*}*/
/*.ql-picker.ql-size .ql-picker-item[data-value=huge]::before {*/
/*  font-size: 32px;*/
/*}*/
/*.ql-color-picker.ql-background .ql-picker-item {*/
/*  background-color: #fff;*/
/*}*/
/*.ql-color-picker.ql-color .ql-picker-item {*/
/*  background-color: #000;*/
/*}*/
/*.ql-toolbar{*/
/*  border: 1px solid #ccc;*/
/*  box-sizing: border-box;*/
/*  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;*/
/*  padding: 8px;*/
/*}*/
/*.ql-toolbar.ql-formats {*/
/*  margin-right: 15px;*/
/*}*/
/*.ql-toolbar.ql-picker-label {*/
/*  border: 1px solid transparent;*/
/*}*/
/*.ql-toolbar.ql-picker-options {*/
/*  border: 1px solid transparent;*/
/*  box-shadow: rgba(0,0,0,0.2) 0 2px 8px;*/
/*}*/
/*.ql-toolbar.ql-picker.ql-expanded .ql-picker-label {*/
/*  border-color: #ccc;*/
/*}*/
/*.ql-toolbar.ql-picker.ql-expanded .ql-picker-options {*/
/*  border-color: #ccc;*/
/*}*/
/*.ql-toolbar.ql-color-picker .ql-picker-item.ql-selected,*/
/*.ql-toolbar.ql-color-picker .ql-picker-item:hover {*/
/*  border-color: #000;*/
/*}*/
/*.ql-toolbar+ .ql-container{*/
/*  border-top: 0px;*/
/*}*/
/*.ql-tooltip {*/
/*  background-color: #fff;*/
/*  border: 1px solid #ccc;*/
/*  box-shadow: 0px 0px 5px #ddd;*/
/*  color: #444;*/
/*  padding: 5px 12px;*/
/*  white-space: nowrap;*/
/*}*/
/*.ql-tooltip::before {*/
/*  content: "Visit URL:";*/
/*  line-height: 26px;*/
/*  margin-right: 8px;*/
/*}*/
/*.ql-tooltip input[type=text] {*/
/*  display: none;*/
/*  border: 1px solid #ccc;*/
/*  font-size: 13px;*/
/*  height: 26px;*/
/*  margin: 0px;*/
/*  padding: 3px 5px;*/
/*  width: 170px;*/
/*}*/
/*.ql-tooltip a.ql-preview {*/
/*  display: inline-block;*/
/*  max-width: 200px;*/
/*  overflow-x: hidden;*/
/*  text-overflow: ellipsis;*/
/*  vertical-align: top;*/
/*}*/
/*.ql-tooltip a.ql-action::after {*/
/*  border-right: 1px solid #ccc;*/
/*  content: 'Edit';*/
/*  margin-left: 16px;*/
/*  padding-right: 8px;*/
/*}*/
/*.ql-tooltip a.ql-remove::before {*/
/*  content: 'Remove';*/
/*  margin-left: 8px;*/
/*}*/
/*.ql-tooltip a {*/
/*  line-height: 26px;*/
/*}*/
/*.ql-tooltip.ql-editing a.ql-preview,*/
/*.ql-tooltip.ql-editing a.ql-remove {*/
/*  display: none;*/
/*}*/
/*.ql-tooltip.ql-editing input[type=text] {*/
/*  display: inline-block;*/
/*}*/
/*.ql-tooltip.ql-editing a.ql-action::after {*/
/*  border-right: 0px;*/
/*  content: 'Save';*/
/*  padding-right: 0px;*/
/*}*/
/*.ql-tooltip[data-mode=link]::before {*/
/*  content: "Enter link:";*/
/*}*/
/*.ql-tooltip[data-mode=formula]::before {*/
/*  content: "Enter formula:";*/
/*}*/
/*.ql-tooltip[data-mode=video]::before {*/
/*  content: "Enter video:";*/
/*}*/
/*a {*/
/*  color: #06c;*/
/*}*/

.modal-background {
    height: 100%;
    width: 100%;
    background-color: rgba(18,26,78,0.23);
    position: fixed;
    top: 0;
    left: 0;
}
.main-div-delete-task-modal {
    height: 410px;
    width: 511px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 30px 0 rgba(75,99,241,0.1);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 262px;
}
.big-circle {
    border-radius: 50%;
    background-color: rgba(97,120,255,0.1);
    height: 160px;
    width: 160px;
    margin-top: 37px;
    margin-left: auto;
    margin-right: auto;
}
.small-circle {
    height: 96px;	
    width: 96px;	
    background-color: #FFFFFF;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    position: relative;
}
.delete-img {
    position: absolute;
    top: 25px;
    right: 26px;
}
.delete-modal-text {
    color: #201E75;
    font-family: 'Heebo', sans-serif;
    font-size: 25px;
    letter-spacing: -0.2px;
    margin-left: auto;
    margin-right: auto;
}
.delete-button-wrap{
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
}
.delete-task-btn {
    height: 45px;
    width: 142px;
    background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    border: unset;
    margin-left: auto;
    margin-right: auto;
    margin-top: 22px;
    margin-bottom: 8px;
    color: #FFFFFF;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;	
    font-weight: bold;	
    letter-spacing: -0.13px;
}
.cancel-btn {
    border: unset;
    background: unset;
    color: #6178FF;
    font-size: 14px;
    letter-spacing: -0.11px;
    font-family: 'Heebo', sans-serif;
}
.div-chat-box-ather {
  padding: 11px 14px 10px 15px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.chat-text-ather {
  color: rgba(18, 26, 78, 0.52);
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-box-footer-ather {
  justify-content: space-between;
  display: flex;
  margin-top: 11px;
}
.write-by-ather {
  color: #c6c4df;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-name-ather {
  font-family: 'Heebo', sans-serif;
  color: #151c5b;
  margin-right: 4px;
}
.box-next-to-message {
  box-sizing: border-box;
  height: 29px;
  width: 17px;
  border-bottom-right-radius: 8px;
  border-bottom: 2px solid #dfe2ea;
  border-right: 2px solid #dfe2ea;
  background-color: #f2f4fc;
  min-width: 17px;
  margin-left: 10px;
  margin-top: 10px;
}

@media (max-width: 500px) {
  .write-by-ather,
  .chat-text-ather,
  .chat-name-ather {
    font-size: 12px;
  }
  .div-chat-box-ather {
    padding: 0px;
  }
}

.div-chat-box-user {
  padding: 11px 14px 10px 15px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.chat-text-user {
  color: rgba(18, 26, 78, 0.52);
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-box-footer-user {
  justify-content: space-between;
  display: flex;
  margin-top: 11px;
}
.write-by-user {
  color: #c6c4df;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-name-user {
  font-family: 'Heebo', sans-serif;
  color: #151c5b;
  margin-right: 4px;
}

@media (max-width: 500px) {
  .write-by-user,
  .chat-text-user,
  .chat-name-user {
    font-size: 12px;
  }
  .div-chat-box-user {
    padding: 0px;
  }
}

.div-all-leads-names {
    color: #121A4E;
    font-family: Heebo;
    font-size: 16px;
    font-weight: 500;
    background-color: #F2F4FC;
    margin-top: 5px;
    margin-right: 5px;
    text-align: center;
}
.div-more-ifo {
  /* height: 100vh; */
  width: 18%;
  background-color: #f2f4fc;
  padding-top: 58px;
  padding-left: 29px;
  padding-right: 30px;
  position: relative;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
}
.more-info-header {
  color: #1a1d68;
  font-family: 'Heebo', sans-serif;
  font-size: 30px;
  letter-spacing: -0.24px;
}
.line-ander-header {
  margin-top: 8px;
  box-sizing: border-box;
  height: 2px;
  border: 1px solid rgba(18, 26, 78, 0.07);
}
.line-ander-Leading-mission {
  box-sizing: border-box;
  height: 2px;
  margin-top: 26.25px;
  border: 1px solid rgba(18, 26, 78, 0.07);
  box-shadow: 0 1px 0 0 rgba(224, 228, 246, 0.27);
}
.div-status-a {
  justify-content: space-between;
}
.status-text {
  color: #121a4e;
  font-family: 'Heebo', sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-top: 24px;
}
.status-num {
  color: #1a1d68;
  font-family: Heebo;
  font-size: 25px;
  letter-spacing: -0.2px;
  margin-top: 16px;
}
.more-info-progress {
  height: 12px;
  margin-top: 2px;
  border-radius: 6px;
  background-color: #e6e9f6;
  box-shadow: inset 0 0 6px 0 rgba(18, 26, 78, 0.06);
}
.more-info-progress-colourful {
  height: 12px;
  margin-top: 2px;
  border-radius: 6px;
  background-color: #07dccd;
  box-shadow: inset 0 0 6px 0 rgba(18, 26, 78, 0.06);
}
.Leading-mission-text {
  color: #121a4e;
  font-family: Heebo;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-top: 25px;
}
.select-leads-btn {
  /* background: url('../../../ui/images/arrow_down.svg'); */
  background-repeat: no-repeat;
  background-position: left;
  margin-top: 17px;
  box-sizing: border-box;
  background-color: #f2f4fc;
  border: unset;
  border-bottom: 1px solid rgba(18, 26, 78, 0.6);
  width: 100%;
  color: #151b5a;
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  letter-spacing: -0.13px;
  -webkit-appearance: none;
  padding-bottom: 10px;
}
.select-leads-btn-intenal {
  display: flex;
  justify-content: space-between;
}
.select-leads-btn-logo {
  margin-bottom: 4px;
}
.select-leads-btn-text {
  margin-bottom: 11px;
}
.forum-header {
  display: flex;
  margin-top: 24.25px;
}
.forum-header-text {
  color: #121a4e;
  font-family: 'Heebo', sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-right: 9px;
}
.div-chat-box {
  padding: 11px 14px 10px 15px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  /* זמני */
  margin-top: 10px;
  /* זמני */
}
.chat-text-me {
  color: rgba(18, 26, 78, 0.52);
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-box-footer {
  /* color: #C6C4DF;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    letter-spacing: -0.11px; */
  justify-content: space-between;
  display: flex;
  margin-top: 11px;
}
.chat-name-me {
  font-family: Heebo;
  color: #151c5b;
  margin-right: 4px;
}
.chat-write-message {
  border: unset;
  min-height: 109px;
  width: 100%;
  border-radius: 3px;
  background-color: #ebeefb;
  /* opacity: 0.6;	 */
  color: #281f84;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
  padding-top: 15px;
  padding-right: 17px;
}
.under-write-message {
  margin-top: 9px;
  /* Tuvia */
  /* display: flex; */
  justify-content: space-between;
}
.write-by {
  color: #c6c4df;
  font-family: Heebo;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.forum-send-message {
  border: unset;
  box-sizing: border-box;
  height: 36px;
  width: 74px;
  border: 1px solid #daddee;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
  color: #a5a6c1;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
  margin-right: 6px;
}
.attach-files-btn {
  border: unset;
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  border: 1px solid #daddee;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px -1px rgba(67, 74, 91, 0.27);
  color: #a5a6c1;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.11px;
}
.div-ander-ander-write-message-write-by {
  margin-top: 7px;
}
.list-of-leads {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 20px 34px 0 rgba(21, 36, 120, 0.21);
  position: absolute;
  width: 297px;
  top: 298px;
  left: 29px;
  padding: 5px;
}
.forum-chat {
  margin-top: 6px;
  margin-bottom: 25px;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  max-height: 200px;
  min-height: 100px;
}
.no-message-text {
  opacity: 0.3;
  color: #281f84;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
  margin-top: 21px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(18, 26, 78, 0.4);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #121a4e;
}

@media (max-width: 500px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .div-more-ifo {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    width: 25%;
  }
  .more-info-header {
    display: none;
  }
  .line-ander-Leading-mission {
    margin-top: 0px;
  }
  .headerDiv {
    display: block;
  }
  .createHeader {
    font-size: 10px;
    font-weight: 700;
  }
  .forum-send-message {
    width: 52px;
  }
  .CreateTaskPage {
    width: 60%;
  }
  .forum-chat {
    min-height: 65px;
  }
}

.delete-button {
    border: unset;
    background: unset;
    margin-right: 10px;
    margin-bottom: auto;
    margin-top: 32px;
}
.copy-task-btn {
    border: unset;
    box-sizing: border-box;
    height: 36px;
    width: 126px;
    border: 1px solid #DADDEE;
    border-radius: 3px;
    background-color: #FFFFFF;
    color: #A4A6C1;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.11px;
}
.copy-task-btn:hover {
    color:#6178ff;
    border: 1px solid #6178ff;
}
.copy-task-btn-is-not-isStaff {
    border: unset;
    box-sizing: border-box;
    height: 36px;
    width: 126px;
    border: 1px solid #DADDEE;
    border-radius: 3px;
    background-color: #FFFFFF;
    color: #A4A6C1;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.11px;
}
.hiddenPage{
     /* display: none; */
    /* border: 1px solid black; */
    margin: 0px;
    direction: rtl;
    width: 100%;
    padding: 10px;
     /*position: fixed;*/
    background-color: white;
}

table{
    table-layout: fixed;
}
.config-page{
    width: 100%;
}

.img-config{
    position: relative;
    height: 120px;
    margin-top:20px;
    margin-right: 31px;
    z-index: 2;
}

.content-config{
    padding: 60px;
    margin-top: 20px;
    color: #121A4E;
    font-size: 25px;
    padding-top: 10px;
}

h3.config{
    margin-top: 50px;
}

.descripation-title{
    font-weight: bold;
    font-weight:700;
    letter-spacing: -0.2px;
    line-height: 37px;
}

.circlel{
    position: relative;
    right: -10px;
    /* top: 15px; */
    /* height: 10px; */
    display: inline-block;
    /* height: 0px; */
    line-height: 20px;
    font-size: 70px;
    color: #dee2e6;
    width: 15px;
}

.circlel-child{
    font-size: 50px;
    right: 40px;
}

.process-content{
    font-size: 22px;
}

.td-border-top{

    border-top: 2px solid #dee2e6;
}

div tbody tr.tr-border-top{
    border-top: 2px solid #dee2e6;
}
div > table > tbody > tr.tr-border-top:first-child{
    border-top: none;
}
div > table > tbody > tr.tr-border-top:last-child{
    border-bottom:2px solid #dee2e6;
}

.tr-margin{
    padding-top: 35px;
    padding-bottom: 5px;
    padding-left: 36px;

}
.tr-margin:first-child{
    /* margin-top: 0; */
}

.tr-child-margin{
    margin-right: 30px;
    margin-left: 30px;
    padding-left: 0px;

}

.date-text{
    color: #6C7582;
}

.mein-header, .mein-header-space{
    height: 75px;
    width: 100%;
    margin-left: 0px;
    margin-right: -10px;
}

.mein-header-space{
    height: 150px;
}

.mein-footer, .mein-footer-space {
  height:110px;
  width: 100%;
  margin-left: 0px;
  margin-right: -10px;
}

.mein-header{
  position:fixed;
  top: 0;
  background-color: #6178ff;
}
.mein-header img{

}

.mein-footer{
  position: fixed;
  bottom: 0;
  background-color: #E4E4E4;
  display: table;
}

#content-footer{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.mein-title {
  font-size: 35px;
  font-weight: bold;
  letter-spacing: -0.28px;
  line-height: 70px;
}

.berake-inside{
  flex: none;
  page-break-inside: auto !important;
  -webkit-column-break-inside: auto !important;
          break-inside: auto !important;
} 
    
.tbody-breaks{
  page-break-inside:auto;
} 

.tr-breaks{
  page-break-inside: avoid;
} 

body{
  height: auto!important;
  float: none!important;
}
    
@media print{
    .hiddenPage{
        display: block;
    } 
    
    .thead-breaks{
        page-break-before: avoid;
    }
    
    table thead tbody tr td {
        display: inline-block;
    }
        
}
.class-copy-task-main {
    height: 100%;
    width: 100%;
    background-color: rgba(18,26,78,0.23);
    position: fixed;
    top: 0;
    left: 0;
}
.copy-task-modal {
    height: 410px;
    width: 511px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 30px 0 rgba(75,99,241,0.1);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 262px;
    padding: 46px 46px 30px 46px;
    position: relative;
}
.copy-task-modal-header1 {
    color: #272084;
    font-family: 'Heebo', sans-serif;
    font-size: 25px;	
    font-weight: 800;	
    letter-spacing: -0.2px;	
    line-height: 37px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.copy-task-modal-header2 {
    color: #1F1E75;
    font-family: 'Heebo', sans-serif;	
    font-size: 25px;	
    letter-spacing: -0.2px;	
    line-height: 37px;
    margin-left: auto;
    margin-right: auto;
}
.cancel-btn {
    background: unset;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    color: #6178FF;
    border: unset;
}
.copy-task-select {
    border-bottom: 2px solid rgba(18,26,78,0.6);
    box-sizing: border-box;
    padding-bottom: 14px;
    display: flex;
    position: relative;
    height: 42px;
}
.copy-task-select-arrow-icon {
    margin-right: auto;
    margin-left: 7px;
}
.copy-task-modal-div-option {
	box-sizing: border-box;
	border: 1px solid #D5D9F0;
	background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
    padding: 16px;
}

.select-label-to-copy-modal {
    color: #151C5A;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    letter-spacing: -0.1px;
    margin-top: 37px;
}
.copy-array-btn {
    height: 45px;
    width: 142px;
    background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    font-family: 'Heebo', sans-serif;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.13px;
    margin-right: 20px;
}
.copy-array-btn-text {
    margin-right: 8px;
}
.div-copy-task-buttons {
    margin-right: auto;
    margin-top: auto;
}
.copy-task-modal-category-option-img {
    height: 24px;
    width: 24px;
    margin-left: 10px;
}

.copy-task-modal-category-option-text {
	color: #181E68;
	font-family: 'Heebo', sans-serif;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: -0.13px;
}

.div-line-find {
    height: 61px;
    width: 1513px;
    display: flex;
    padding: 10px;
    border: unset;
    position: relative;
}
.text-find-header {
    color: #121A4E;
    font-family: Heebo;
    font-size: 15px;
    letter-spacing: -0.12px;
    margin-top: 10px;
    margin-right: 7px;
}
.yesod-find-logo {
    position: absolute;
    top: 35px;
    right: 5px;
}

.div-all-find {
    position: relative;
    z-index: 999999;
}
.all-find {
    position: absolute;
    height: 325px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 34px 0 rgba(21,36,120,0.21);
    padding: 10px;
  border-top: 2px solid #6178FF;
}

.search-field{
  border: unset;
  height: 60px;
  width: 85%;
  color: #121A4E;
  font-family: 'Heebo', sans-serif;
  font-size: 25px;
  letter-spacing: -0.2px;
  /*border-bottom: 2px solid rgba(18, 26, 78, 0.16);*/
  padding-right: 20px;
  background-color: #E7EBFF;
}
.search-field .search-input {
    border: unset;
    /*height: 55px;*/
    /*width: 100%;*/
    /*color: #121A4E;*/
    /*font-family: 'Heebo', sans-serif;*/
    /*font-size: 25px;*/
    /*letter-spacing: -0.2px;*/
    /*border-bottom: 2px solid rgba(18, 26, 78, 0.16);*/
    padding-right: 20px;
    background-color: #E7EBFF;

}
.search-field .search-input-border-bottom {
    box-sizing: border-box;
    height: 1px;
    width: 1533px;
    border: 2px solid #121A4E;
    opacity: 0.16;
}
.search-field .div-search-input-logo {
  /*background-color: #E7EBFF;*/
    /* border-bottom: 2px solid rgba(18, 26, 78, 0.16); */
    width: 25px;
    padding-left: 3px;
    line-height: 60px;
}

.my-tag {
    margin-top: 23px;
    margin-right: 13px;
    border-radius: 30px;
    padding: 0px 9px 0px 9px;
    border: 1px solid rgba(18,26,78,0.1);
    line-height: 1.9;
    background: #FFFFFF;
}
.my-tag-to-all {
    margin-top: 23px;
    margin-right: 13px;
    border-radius: 30px;
    padding: 0px 9px 0px 9px;
    border: 1px solid rgba(18,26,78,0.1);
    line-height: 1.9;
    background: #6178FF;
    color: #FFFFFF;
}

.TagSelected {
  height: 33px;
  color:#FFFFFF;
  border-radius: 19px;
  background-color: #6178FF;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  margin-left: 9px;
  cursor: pointer;
}

.TagNotSelected {
  height: 33px;
  color: #717695;
  border-radius: 19px;
  background-color: #DADDEE;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  margin-left: 9px;
  cursor: pointer;
}

.filtering-text {
  color: #121A4E;
  font-family: Heebo;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.14px;
  line-height: 27px;
  margin-left: 20px;
}

.main-page {
    display: flex;
    height: 100vh;
    background-color:#F8F9FF ;
}
.sidebar-line {
    border: 0.5px solid rgba(18,26,78,0.04);
    /*width: 14%;*/
}
.div-main {
    padding-top: 51px;
    padding-left: 84px;
    padding-right: 84px;
    min-width: 85vw;
    display: flex;
    flex-direction: column;
    font-family: Heebo;
}
.div-main .top-img{
  margin-bottom: 72px;
}
.div-main .welcome{
  color: #151C5B;
  /*font-family: Heebo;*/
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 24px;
}
.div-main .welcome .welcome-title{

}
.div-main .welcome .welcome-description{
  color: #121A4E;
  font-size: 20px;
  /*font-weight: 500;*/
  letter-spacing: -0.2px;
  line-height: 37px;
}
.div-navber-header {
    margin-top: 35px;
}
.div-all-cards-vertical {
    flex-wrap: wrap;
    margin-top: 32px;
    height: calc(100vh - 110px);
    overflow: scroll;
}
.div-all-cards-horizontal {
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 30px;
  height: calc(100vh - 610px);
  overflow: scroll;
}
.div-all-cards-vertical>a {
  text-decoration: none;
}

.div-all-cards-horizontal>a{
  text-decoration: none;
}
.mainFooter {
    color: #151C5B;
    font-family: 'Heebo', sans-serif;
    font-size: 15px;
    letter-spacing: -0.12px;
    display: flex;
    justify-content:space-between;
    padding-left: 53px;
}
.footer-search-logo {
    margin-left: 4px;
}
.plus-mission {
    position: fixed;
    top: 30px;
    left: 95px;
    height: 60px;
    width: 171px;
    border-radius: 30px;
    background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);

}
.plus-mission> a > #plus-text {
   font-size: 16px;
   line-height: 24px;
   color: white;
   text-decoration: none;
   padding: 11%;
   position: absolute;
   width: 100%;
   text-align: end;
   font-weight: bold;
   letter-spacing: 0.5px;
}
.plus-mission> a > #plus {
   font-size: xxx-large;
   color: white;
   padding-top: 12%;
   padding-right: 7%;
   line-height: 24px;
   text-decoration: none;
   position: absolute;
   width: 100%;
}
.search-up{
  /*background-color: #FF8B90;*/
  position: absolute;
  top: 30px;
  width: 75%;
}
.class-all-category-folders {
    overflow: scroll;
    height: calc(100vh - 100px);
}

.folder-area,
.tasks-area {
  margin: 20px 0;
}

.tasks-area {
  display: flex;
  flex-wrap: wrap;
}

.folders-header {
  flex: 0 0 100%;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
}

.folder-builder-wraper {
  display: flex;
  flex-wrap: wrap;
}

.folder-card {
  width: 260px;
  height: 82px;
  /* color: #FFFFFF; */
  color: #121A4E;
  /* background: linear-gradient(123.73deg, #6178FF -20.43%, #4860E8 93.12%); */
  background: #CBD2FA;
  border-radius: 10px;
  margin: 0 5px 24px 20px;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  cursor: pointer;
}

.folder-card:hover {
  box-shadow: 0 10px 20px 0 rgba(21, 36, 120, 0.13);
}

.folder-right-part {
  display: flex;
  align-items: center;
}

.folder-left-part {}

.folder-drag-over {
  width: 274px;
  height: 96px;
  background-color: #E7EBFF;
  border: 1px solid #6178FF;
  border-radius: 14px;
  margin: 0 0 10px 11px;
}

.folder-no-over {
  background-color: none;
  border: none;

}

.is-dragging {
  opacity: 50%;
}

.folder-drag-over>.folder-card,
.folder-no-over>.folder-card {
  position: relative;
  top: 6px;
  right: 5px;

}

.folder-icon-wraper {
  background-color: #6178FF;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  margin-left: 13px;
}

.folder-icon {
  position: relative;
  top: 7px;
  right: 11.5px;
}

.folder-icon::before {
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 900;
  font-family: Awesome;
  content: "\f07b";
}

.action-icon-wraper {
  width: 8px;
  text-align: center;
}

.action-icon {
  top: 16px;
}

.action-icon::before {
  color: #4d89ea;
  font-size: 15px;
  font-weight: 900;
  font-family: Awesome;
  content: "\f142";
}

.folder-name {
  max-width: 165px;
  max-height: 70px;
  overflow: hidden;
}

.folder-name-input {
  background: #FFFFFF;
  /* border: 1px solid #6178FF; */
  border: none;
  border-radius: 8px;
  height: 30px;
  /* caret-color: #6178FF; */
  caret-color: #CBD2FA;
  padding: 0 10px;
  color: #121A4E;
  width: 150px;
}

.display-none {
  display: none;
}

.between-cards {
  position: relative;
}

.wrap-line-between {
  position: absolute;
  width: 50px;
  z-index: 20;
}

.wrap-line-between.folder {
  left: -22px;
  top: 6px;
  height: 82px;
}

.wrap-line-between.folder.first {
  left: -26px;
}

.wrap-line-between.task {
  left: -25px;
  top: 12px;
  height: 177px;
}

.wrap-line-between.task.first {
  left: -26px;
}

.line-between-tasks {
  border: #6178FF 1px solid;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
}

.line-between-tasks.folder {
  margin-top: 6px;
  height: 67px;
}

.line-between-tasks.task {
  margin-top: 5px;
  height: 164px;
}
.div-task-card {
    width: 364px;
    max-height: 176px;
    border-radius: 3px;
    background-color: #FFFFFF;
    margin: 13px 9px 13px 9px;
    padding: 20px;
    position: relative;
}

.div-task-card>span.wrap-img {
    position: absolute;
    left: 14px;
    top: -15px;
}

.div-task-card>span>img.categoryLogo {
    position: relative;
    /* left: 14px;
    top: -15px; */
}

.div-task-card>span>img.lock {
    position: relative;
    /* margin-right: -10px; */
    margin-top: 15px;
    width: 10px;
    height: 10px;
}

.my-card-header {
    height: 44px;
    width: 299px;
    display: flex;
}

.div-my-card-header-logo {
    margin-top: 1.5px;
    margin-bottom: 1.5px;
}

.div-my-card-header-text {
    color: #121A4E;
    font-family: 'Heebo', sans-serif;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.12px;
    /*line-height: 22px;*/
    margin-right: 6px;
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.8em;
}

.my-card-line {
    height: 1px;
    width: 325px;
    border: 1px solid rgba(18, 26, 78, 0.07);
    margin-top: 15px;
}

.my-essence-mission {
    height: 36px;
    color: #727797;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 18px;
    margin-top: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.my-card-footer {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    margin-bottom: 8px;
}

.my-footer-text {
    color: #727797;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.1;
    margin-right: 6px;
    margin-top: 5px;
}

.draft {
    color: #727797;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-top: 6px;
}

.class-draft-header-text {
    height: 18.9%;
    width: 19.8%;
    border-radius: 3px;
    background-color: #F0F0F0;
    color: #8F8F8F;
    font-family: 'Heebo', sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 18px;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 5px;
}

.card-logo {
    height: 20px;
    width: 20px;
}
.div-all-cards-vertical {
  flex-wrap: wrap;
  /* margin-top: 32px; */
  height: calc(100vh - 110px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.wrap-path {
  flex: 0 0 100%;
  margin: 46px 0 24px;
  display: flex;
  justify-content: space-between;
}

.path-right-part {}

.path-left-part {
  margin-left: 25px;
}

.bagin-path {
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  cursor: pointer;
}

.continu-path {
  font-weight: 400;
  cursor: pointer;
}

.add-task-icon::before {
  font-family: Awesome-Duotone;
  font-weight: 900;
  color: #FFFFFF;
  content: "\2b";
}

.add-window {
  position: absolute;
  height: 200px;
  width: 200px;
  border: solid #987789 1px;
}

.add-task,
.folder-actions-popover {
  background-color: #FFFFFF;
  border: 1px solid rgba(190, 199, 229, 0.3);
  box-shadow: 0px 0px 50px rgba(204, 210, 227, 0.45);
  border-radius: 10px;
}

.add-task>.popover-inner>.popover-body,
.folder-actions-popover>.popover-inner>.popover-body {
  background-color: #FFFFFF;
  color: #121A4E;
  width: 162px;
  border: none;
  border-radius: 10px;
}

.add-item {
  height: 37px;
  border-radius: 8px;
  line-height: 37px;
  text-align: start;
  padding: 0 16px;
  cursor: pointer;
}

.add-item:hover {
  background-color: #E7EBFF;
}

.add-item-taxt {}

.add-item-icon {}

.add-item-icon::before {
  width: 14px;
  height: 14px;
  font-family: Awesome;
  font-weight: 400;
  font-size: 14px;
  padding: 0 0 0 10px;
  color: #6178FF;
}

.add-folder-icon::before {
  content: '\e18c';
}

.task-icon::before {
  content: '\f319';
}

.pen-icon::before{
  content: '\e212';
}

.delete-icon::before{
  content: '\f1f8';
}

.metarim{
  font-weight: 400;
  color: #6178FF;
}

.modal-background {
    height: 100%;
    width: 100%;
    background-color: rgba(18,26,78,0.23);
    position: fixed;
    top: 0;
    left: 0;
}
.main-div-create-library-modal {
    height: 38.87%;
    width: 26.61%;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 30px 0 rgba(75,99,241,0.1);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 262px;
    padding: 46px;
}
.create-library-header {
	color: #272084;
	font-family: 'Heebo', sans-serif;
	font-size: 25px;
    font-weight: 800;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 31px;
}
.create-library-modal-cancel-btn {
    background: unset;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    color: #6178FF;
    border: unset;
}
.create-library-label {
    color: #151C5A;	
    font-family: 'Heebo', sans-serif;	
    font-size: 12px;
    margin-bottom: 2%;
}
.create-library-input {
    border: unset;
    border-bottom: 1px solid rgba(18,26,78,0.6);
    color: #121A4E;	
    font-family: 'Heebo', sans-serif;
    font-size: 16px;	
    font-weight: bold;
    padding-bottom: 3.7%;
}
.div-add-file-in-create-library-modal {
    margin-top: 20px;
    /* margin-bottom: 51px; */
}
.add-file-text {
    color: #141C5B;	
    font-family: 'Heebo', sans-serif;	
    font-size: 12px;
}
.add-file-to-create-library-modal-btn {
    border: unset;
    background: unset;
    color: #6178FF;	
    font-family: 'Heebo', sans-serif;
    font-size: 14px;	
    font-weight: bold;
    margin-right: 6px;
}
.create-library-btn-action {
	background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    border: unset;
    color: #FFFFFF;	
    font-family: 'Heebo', sans-serif;	
    font-size: 16px;
    font-weight: bold;
    padding: 10px 26px;
    letter-spacing: -0.13px;
    line-height: 24px;
    margin-right: 16px;
}
.create-library-modal-buttons {
    display: flex;
    margin-right: auto;
    margin-top: auto;
}

.my-home-page-logo {
    background-color: #FFFFFF;
    height: 51px;
    width: 52px;
    border-radius: 50%;
    padding-top: 7px;
    padding-right: 8px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 7px;
}
@-webkit-keyframes swing {
  0%, 30%, 50%, 70%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}
@keyframes swing {
  0%, 30%, 50%, 70%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}
.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #1d1d1d;
  height: 100%;
  position: relative;
  z-index: 101;
}
.pro-sidebar > .pro-sidebar-inner > img.sidebar-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  opacity: 0.3;
  left: 0;
  top: 0;
  z-index: 100;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  flex-grow: 1;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border-top: 1px solid rgba(173, 173, 173, 0.2);
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.pro-sidebar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
}
.pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
.pro-sidebar.rtl {
  text-align: right;
  direction: rtl;
}
@media (max-width: 480px) {
  .pro-sidebar.xs {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.xs.collapsed {
    left: -80px;
  }
  .pro-sidebar.xs.toggled {
    left: 0;
  }
  .pro-sidebar.xs.toggled .overlay {
    display: block;
  }
  .pro-sidebar.xs.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.xs.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.xs.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 576px) {
  .pro-sidebar.sm {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.sm.collapsed {
    left: -80px;
  }
  .pro-sidebar.sm.toggled {
    left: 0;
  }
  .pro-sidebar.sm.toggled .overlay {
    display: block;
  }
  .pro-sidebar.sm.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.sm.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.sm.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 768px) {
  .pro-sidebar.md {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.md.collapsed {
    left: -80px;
  }
  .pro-sidebar.md.toggled {
    left: 0;
  }
  .pro-sidebar.md.toggled .overlay {
    display: block;
  }
  .pro-sidebar.md.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.md.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.md.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 992px) {
  .pro-sidebar.lg {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.lg.collapsed {
    left: -80px;
  }
  .pro-sidebar.lg.toggled {
    left: 0;
  }
  .pro-sidebar.lg.toggled .overlay {
    display: block;
  }
  .pro-sidebar.lg.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.lg.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.lg.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 1200px) {
  .pro-sidebar.xl {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.xl.collapsed {
    left: -80px;
  }
  .pro-sidebar.xl.toggled {
    left: 0;
  }
  .pro-sidebar.xl.toggled .overlay {
    display: block;
  }
  .pro-sidebar.xl.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.xl.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.xl.rtl.toggled {
    left: auto;
    right: 0;
  }
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: "";
  display: inline-block;
  width: 4px;
  min-width: 4px;
  height: 4px;
  border: 1px solid #2b2b2b;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  box-shadow: 1px 0px 0px #adadad, 0px -1px 0px #adadad, 0px 1px 0px #adadad, -1px 0px 0px #adadad;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pro-sidebar .pro-menu {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #2b2b2b;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #adadad;
}
.pro-sidebar .pro-menu a:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}
.pro-sidebar .pro-menu a:hover {
  color: #d8d8d8;
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 15px;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #d8d8d8;
}
.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}
.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
  display: flex;
  margin-right: 5px;
  opacity: 1;
  transition: opacity 0.2s;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 35px 8px 20px;
  cursor: pointer;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  outline: none;
  color: #d8d8d8;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 10px;
  font-size: 14px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #d8d8d8;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  -webkit-animation: swing ease-in-out 0.5s 1 alternate;
          animation: swing ease-in-out 0.5s 1 alternate;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item:before {
  background: #adadad;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-color: #adadad;
  border-width: 0 2px 2px 0;
  padding: 2.5px;
  vertical-align: middle;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item:before {
  background: transparent !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 20px;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 30px 8px 15px;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
  display: none;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
  display: none;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: #2b2b2b;
}
.pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 0;
}
.pro-sidebar .pro-menu.round .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 4px;
}
.pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 50%;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item {
  position: relative;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .suffix-wrapper,
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .prefix-wrapper {
  opacity: 0;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
  background-color: #2b2b2b;
  z-index: 111;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu {
  position: relative;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item {
  pointer-events: none;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  display: none;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  height: auto !important;
  position: fixed;
  visibility: hidden;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
  padding-left: 3px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
  padding-left: 10px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  max-height: 100vh;
  overflow-y: auto;
  background-color: #2b2b2b;
  padding-left: 20px;
  border-radius: 4px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover > .pro-inner-list-item {
  transition: visibility, -webkit-transform 0.3s;
  transition: visibility, transform 0.3s;
  transition: visibility, transform 0.3s, -webkit-transform 0.3s;
  visibility: visible;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
  -webkit-animation: swing ease-in-out 0.5s 1 alternate;
          animation: swing ease-in-out 0.5s 1 alternate;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 30px 8px 5px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 20px 8px 35px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 0;
  margin-left: 10px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  right: auto;
  left: 20px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 20px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 15px 8px 30px;
}
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  margin-right: 0;
  margin-left: 15px;
}
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px;
}
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
  padding-right: 10px;
}
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  padding-left: 0;
  padding-right: 20px;
}
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 5px 8px 30px;
}

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^=left] > .popper-arrow {
  right: 0;
  border-right: 7px solid #2b2b2b;
}

.popper-element[data-popper-placement^=right] > .popper-arrow {
  left: 0;
  border-left: 7px solid #2b2b2b;
}

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}
.yesod_logo_sidebar {
  margin-left: auto;
  /*padding-top: 10px;*/
  display: block;
  margin-bottom: 20px;
}

.yesod_logo_sidebar_close {
  margin: 10px auto 20px auto;
  padding-top: 10px;
  display: block;
}

#header {
  /*position: absolute;*/
  /*width: 260px;*/
  max-width: 14vw
}

#header .pro-sidebar {
  height: 100vh;
}

#header .header-top {
  background-color: #323654;
  height: 104px;
}

#header .bottom-header {
  margin-top: -20px;
  height: 100px;
  text-align: center;
  color: white;
  font-family: 'Heebo', sans-serif;
  font-weight: bold;
}

#header .closemenu {
  left: 18px;
  color: white;
  position: absolute;
  /*right: 0;*/
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 17px;
  cursor: pointer;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: #282C45;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
  
}

#header .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  /* padding: 0 5px; */
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  font-size: 17px;
  color: white;
  margin: 10px 0px;
  font-weight: bold;
  height: 61px;
}

#header .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  /*text-align: right;*/
  /*flex-grow: 1;*/
  /*flex-shrink: 1;*/
  padding-right: 12px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #282C45;
  color: white;
  border-radius: 3px;
  height: 38px;
  width: 38px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  /*color: #000;*/
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  position: relative;
  background-color: #20243A !important;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  display: none;
}


#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #282C45;
  color: white;
  border-radius: 3px;

}
#header .pro-sidebar-inner .pro-sidebar-layout ul .circleIcon .pro-inner-item .pro-icon-wrapper {
  background-color: white;
  color: white;
  border-radius: 20px;
  height: 38px;
  width: 38px;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
  border: solid #4860E8 1px;
  border-radius: 20px;
  background-color: white !important;
  display: inline-block !important;
}
#header .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  background-color: #282C45;
  padding-right: 20px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  /*background-image: linear-gradient(50deg, #eff1fc 0%, #bab6b4 100%);*/
  background: #6178FF;
  /*border-radius: 0px 25px 25px 0px;*/
  margin-left: -10px;
  margin-right: -30px;
  padding-right: 30px;
}

#header .logo {
  padding: 20px;
}

#addNewFolder {
  /*Font: Heebo Bold;*/
  Size: 16px;
  Line_height: 24px;
  Letter: -0.13px;
  Align: right;
  color: #6178FF

}

#header .pro-sidebar-inner .pro-sidebar-layout ul .active .pro-icon-wrapper {
background-color: inherit;
}

.pro-menu-item.pro-sub-menu.open > .react-slidedown.pro-inner-list-item{
  max-height: calc(100vh - 355px) ;
  /* max-height: calc(100vh - 555px) ; */
  overflow-y: overlay;
  overflow-x: hidden;
}

.pro-menu-item.pro-sub-menu.open > .react-slidedown.pro-inner-list-item::-webkit-scrollbar-thumb{
  border-radius: 15px;
  background-color: #60637A;
}
.pro-menu-item.pro-sub-menu.open > .react-slidedown.pro-inner-list-item::-webkit-scrollbar{
  width: 5px;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.item-menu:active {
  background-color: #2b542c;
}

.item-menu:hover {
  background-color: #1b8dbb;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}




.username-logo {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #07DCCD;
    margin: 0 auto;
    border: solid #282C45 4px ;
}
.username-logo-text {
    color: #121A4E;
    padding: 20%;
}
.username-text{
  font-size: 17px;
}

