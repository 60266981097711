.modal-background {
    height: 100%;
    width: 100%;
    background-color: rgba(18,26,78,0.23);
    position: fixed;
    top: 0;
    left: 0;
}
.main-div-create-library-modal {
    height: 38.87%;
    width: 26.61%;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 30px 0 rgba(75,99,241,0.1);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 262px;
    padding: 46px;
}
.create-library-header {
	color: #272084;
	font-family: 'Heebo', sans-serif;
	font-size: 25px;
    font-weight: 800;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 31px;
}
.create-library-modal-cancel-btn {
    background: unset;
    font-family: 'Heebo', sans-serif;
    font-size: 14px;
    color: #6178FF;
    border: unset;
}
.create-library-label {
    color: #151C5A;	
    font-family: 'Heebo', sans-serif;	
    font-size: 12px;
    margin-bottom: 2%;
}
.create-library-input {
    border: unset;
    border-bottom: 1px solid rgba(18,26,78,0.6);
    color: #121A4E;	
    font-family: 'Heebo', sans-serif;
    font-size: 16px;	
    font-weight: bold;
    padding-bottom: 3.7%;
}
.div-add-file-in-create-library-modal {
    margin-top: 20px;
    /* margin-bottom: 51px; */
}
.add-file-text {
    color: #141C5B;	
    font-family: 'Heebo', sans-serif;	
    font-size: 12px;
}
.add-file-to-create-library-modal-btn {
    border: unset;
    background: unset;
    color: #6178FF;	
    font-family: 'Heebo', sans-serif;
    font-size: 14px;	
    font-weight: bold;
    margin-right: 6px;
}
.create-library-btn-action {
	background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);
    border: unset;
    color: #FFFFFF;	
    font-family: 'Heebo', sans-serif;	
    font-size: 16px;
    font-weight: bold;
    padding: 10px 26px;
    letter-spacing: -0.13px;
    line-height: 24px;
    margin-right: 16px;
}
.create-library-modal-buttons {
    display: flex;
    margin-right: auto;
    margin-top: auto;
}
