.div-chat-box-ather {
  padding: 11px 14px 10px 15px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.chat-text-ather {
  color: rgba(18, 26, 78, 0.52);
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-box-footer-ather {
  justify-content: space-between;
  display: flex;
  margin-top: 11px;
}
.write-by-ather {
  color: #c6c4df;
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  letter-spacing: -0.11px;
}
.chat-name-ather {
  font-family: 'Heebo', sans-serif;
  color: #151c5b;
  margin-right: 4px;
}
.box-next-to-message {
  box-sizing: border-box;
  height: 29px;
  width: 17px;
  border-bottom-right-radius: 8px;
  border-bottom: 2px solid #dfe2ea;
  border-right: 2px solid #dfe2ea;
  background-color: #f2f4fc;
  min-width: 17px;
  margin-left: 10px;
  margin-top: 10px;
}

@media (max-width: 500px) {
  .write-by-ather,
  .chat-text-ather,
  .chat-name-ather {
    font-size: 12px;
  }
  .div-chat-box-ather {
    padding: 0px;
  }
}
