.main-page {
    display: flex;
    height: 100vh;
    background-color:#F8F9FF ;
}
.sidebar-line {
    border: 0.5px solid rgba(18,26,78,0.04);
    /*width: 14%;*/
}
.div-main {
    padding-top: 51px;
    padding-left: 84px;
    padding-right: 84px;
    min-width: 85vw;
    display: flex;
    flex-direction: column;
    font-family: Heebo;
}
.div-main .top-img{
  margin-bottom: 72px;
}
.div-main .welcome{
  color: #151C5B;
  /*font-family: Heebo;*/
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 24px;
}
.div-main .welcome .welcome-title{

}
.div-main .welcome .welcome-description{
  color: #121A4E;
  font-size: 20px;
  /*font-weight: 500;*/
  letter-spacing: -0.2px;
  line-height: 37px;
}
.div-navber-header {
    margin-top: 35px;
}
.div-all-cards-vertical {
    flex-wrap: wrap;
    margin-top: 32px;
    height: calc(100vh - 110px);
    overflow: scroll;
}
.div-all-cards-horizontal {
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 30px;
  height: calc(100vh - 610px);
  overflow: scroll;
}
.div-all-cards-vertical>a {
  text-decoration: none;
}

.div-all-cards-horizontal>a{
  text-decoration: none;
}
.mainFooter {
    color: #151C5B;
    font-family: 'Heebo', sans-serif;
    font-size: 15px;
    letter-spacing: -0.12px;
    display: flex;
    justify-content:space-between;
    padding-left: 53px;
}
.footer-search-logo {
    margin-left: 4px;
}
.plus-mission {
    position: fixed;
    top: 30px;
    left: 95px;
    height: 60px;
    width: 171px;
    border-radius: 30px;
    background-color: #6178FF;
    box-shadow: 0 5px 8px 0 rgba(97,120,255,0.27);

}
.plus-mission> a > #plus-text {
   font-size: 16px;
   line-height: 24px;
   color: white;
   text-decoration: none;
   padding: 11%;
   position: absolute;
   width: 100%;
   text-align: end;
   font-weight: bold;
   letter-spacing: 0.5px;
}
.plus-mission> a > #plus {
   font-size: xxx-large;
   color: white;
   padding-top: 12%;
   padding-right: 7%;
   line-height: 24px;
   text-decoration: none;
   position: absolute;
   width: 100%;
}
.search-up{
  /*background-color: #FF8B90;*/
  position: absolute;
  top: 30px;
  width: 75%;
}
.class-all-category-folders {
    overflow: scroll;
    height: calc(100vh - 100px);
}
